import React from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { FaSackDollar } from "react-icons/fa6";
import { MdLocationPin, MdOutlineDateRange } from "react-icons/md";
import ImageSliderTask from "../Common/ImageSliderTask";
import { BudgetFormat, setReportFormatDate } from "../Helper/helper";

const ViewTaskDetail = ({ showTaskDetail, setshowTaskDetail }) => {
  const handleClose = () => {
    setshowTaskDetail((prev) => ({
      ...prev,
      model: false,
      data: [],
    }));
  };

  return (
    <>
      <div className="font-sans antialiased fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"  style={{ zIndex: 999 }}>
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div className="flex flex-col justify-center h-screen">
          <div className="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl shadow-lg p-6 max-w-xs md:max-w-3xl mx-auto border border-white bg-white">
            <XCircleIcon
              onClick={handleClose}
              className="absolute top-2 right-2 h-6 w-6 text-gray-500 cursor-pointer"
            />
            <div className="w-full md:w-1/3 bg-white grid place-items-center">
              {showTaskDetail.data.attachments.length > 0 ? (
                <div className="object-scale-down h-72 w-56" > 
                <ImageSliderTask
                  images={showTaskDetail.data.attachments.map(
                    (item) =>
                      `${process.env.REACT_APP_IMAGE_URL}job_attachments/${item}`
                  )}
                  alt="task image"
                  className="rounded-xl"
                />
                </div>
               
              ) : (
                <img
                  src="../assets/images/no-image.jpg"
                  alt="no image available"
                  className="rounded-xl"
                />
              )}
            </div>
            <div className="w-full md:w-2/3 bg-white flex flex-col space-y-4 p-4">
              <h3 className="font-semibold text-gray-800 md:text-3xl text-xl">
                {showTaskDetail?.data?.title}
              </h3>
              <p className="md:text-lg text-gray-500 text-base">
                {showTaskDetail?.data?.description}
              </p>

              <div className="flex space-x-3 items-center">
                <div className="flex items-center text-gray-800 text-sm">
                  <FaSackDollar className="mr-1" />
                  {/* ${showTaskDetail?.data?.finalPrice} */}
                  ${BudgetFormat(`${showTaskDetail?.data?.finalPrice}`)}
                </div>
                <div className="flex items-center text-gray-600 text-sm">
                  <MdOutlineDateRange className="mr-1" />
                  {setReportFormatDate(showTaskDetail.data.dueDate)}
                </div>
              </div>

              <div className="flex space-x-3 items-center">
                <p
                  className={`font-semibold text-sm uppercase tracking-wide
                    ${
                      showTaskDetail?.data?.status === "in-progress"
                        ? "text-yellow-500"
                        : showTaskDetail?.data?.status === "open"
                        ? "text-blue-600"
                        : showTaskDetail?.data?.status === "pending"
                        ? "text-yellow-300"
                        : showTaskDetail?.data?.status === "closed"
                        ? "text-red-500"
                        : showTaskDetail?.data?.status === "assigned"
                        ? "text-blue-400"
                        : showTaskDetail?.data?.status === "completed"
                        ? "text-green-600"
                        : showTaskDetail?.data?.status === "cancel"
                        ? "text-red-700"
                        : "text-gray-600"
                    }`}
                >
                  {showTaskDetail?.data?.status}
                </p>
                <div className="flex items-center text-gray-600 font-bold text-sm">
                  <MdLocationPin className="text-lg" />
                  <p className="ml-1">
                    {showTaskDetail?.data?.province}
                    <span className="text-gray-500">
                      ( {showTaskDetail?.data?.cityName} )
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTaskDetail;
