import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { FaCheckCircle, FaEnvelopeOpenText } from "react-icons/fa";
import { FaBarsStaggered } from "react-icons/fa6";
import { IoIosEye, IoMdPeople } from "react-icons/io";
import { MdShoppingCart } from "react-icons/md";
import { LuBadgeDollarSign } from "react-icons/lu";

const CardOne = ({ price, text, percent, Icon }) => {
  return (

    
    <div
      className="rounded-[10px] border border-stroke bg-white p-5 shadow-default chart--box" 
    >
      <div className="flex h-11.5 w-11.5 items-center justify-start rounded-full bg-meta-2 dark:bg-meta-4">
        {Icon == "revenue" ? (
          <LuBadgeDollarSign className="text-primary dark:text-black" size={44} />
        ) : Icon == "shoping" ? (
          <MdShoppingCart className="text-primary dark:text-black" size={44} />
        ) : Icon == "user" ? (
          <IoMdPeople className="text-primary dark:text-black" size={44} />
        ) : Icon == "complete" ? (
          <FaCheckCircle className="text-primary dark:text-black" size={44} />
        ) : Icon == "Open" ? (
          <FaEnvelopeOpenText className="text-primary dark:text-black" size={44} />
        ) : Icon == "ongoing" ? (
          <FaBarsStaggered className="text-primary dark:text-black" size={44} />
        ) : (
          <></>
        )}
      </div>

      <div className="flex justify-between chart--info">
        <div className="chart--box--title">
          <span className="text-sm font-[600] mt-1">{text}</span>
        </div>

        <div className="chart--box--title">
          <span className="text-sm font-medium">{percent}</span>
        </div>
      </div>
    </div>
  );
};

export default CardOne;
