import React, { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { authAxios } from "../config/config";
import { toast } from "react-toastify";
import { formatTime } from "../Helper/helper";

const ChatModal = ({ usersChatDetails, setshowChatModel }) => {
  const [searchKeywords, setsearchKeywords] = useState("");
  const DirectorId = usersChatDetails?.participants[0]?._id;

  const handleSearch = (value) => {
    setsearchKeywords(value);

    const messageNodes = document.getElementsByClassName("chat-message");

    for (let i = 0; i < messageNodes.length; i++) {
      const messageText = messageNodes[i]?.textContent?.toLowerCase();
      if (messageText.includes(value.toLowerCase())) {
        messageNodes[i].scrollIntoView({ behavior: "smooth", block: "center" });
        break;
      }
    }
  };

  return (
    <div className="fixed inset-0 z-10">
      <div className="absolute inset-0 bg-gray-500 bg-opacity-75"></div>
      <div className="fixed inset-0 z-10">
        <div className="flex flex-col items-center justify-center w-screen min-h-screen  bg-gray-transparent text-gray-800 p-10">
          <div className="flex flex-col flex-grow w-full max-w-xl bg-white shadow-xl rounded-t-lg overflow-hidden chat--screen">
            <div className="px-[20px] flex items-center justify-between bg-textcolor gap-[10px] h-[60px]">
              <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[40px] max-w-[440px] w-full">
                <IoSearchOutline className="mr-[5px] search--icon" />
                <input
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search"
                  value={searchKeywords}
                  className="input--text focus:outline-none focus:none"
                />
              </div>

              {/* <IoIosCloseCircle
                className="w-10 h-10 cursor-pointer w-[30px] text-white"
                /> */}
              <p
                className="text-white text-[14px] cursor-pointer"
                onClick={() => setshowChatModel(false)}
              >
                Close
              </p>
            </div>

            <div className="flex flex-col flex-grow h-0 p-4 overflow-auto over--scroller bg-[#F0F0F0]">
              {usersChatDetails &&
                usersChatDetails?.messages?.map((item, index) => (
                  <div
                    key={index}
                    className={`chat-message ${
                      searchKeywords &&
                      item?.text
                        ?.toLowerCase()
                        ?.includes(searchKeywords?.toLowerCase())
                        ? "highlight"
                        : ""
                    }`}
                  >
                    {DirectorId === item?.sender ? (
                      <div className="flex w-full mt-2 space-x-3 max-w-xs">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${usersChatDetails?.participants[0]?.profilePic}`}
                          className="flex-shrink-0 h-[45px] w-[45px] rounded-full bg-white object-center object-cover"
                        ></img>
                        <div className="">
                          <div className="bg-white p-3 rounded-r-lg rounded-bl-lg break-all">
                            <p className="text-sm ">
                              {item?.text?.split(/\b/).map((word, i) => {
                                const isPartialMatch =
                                  searchKeywords &&
                                  word
                                    ?.toLowerCase()
                                    .startsWith(searchKeywords?.toLowerCase());
                                return (
                                  <span key={i}>
                                    {isPartialMatch ? (
                                      <span>
                                        <span className="bg-orange-500 text-white">
                                          {word.substring(
                                            0,
                                            searchKeywords.length
                                          )}
                                        </span>
                                        <span>
                                          {word.substring(
                                            searchKeywords.length
                                          )}
                                        </span>{" "}
                                      </span>
                                    ) : (
                                      <span>{word} </span>
                                    )}
                                  </span>
                                );
                              })}
                            </p>
                          </div>
                          <span className="text-xs text-gray-500 leading-none">
                            {formatTime(item?.createdAt)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
                        <div className="text-right">
                        <div className="bg-black text-white p-3 rounded-l-lg rounded-br-lg">
                            <p className="text-sm">
                              {item?.text?.split(/\b/).map((word, i) => {
                                const isPartialMatch =
                                  searchKeywords &&
                                  word
                                    ?.toLowerCase()
                                    ?.startsWith(searchKeywords?.toLowerCase());
                                return (
                                  <span key={i}>
                                    {isPartialMatch ? (
                                      <span>
                                        <span className="bg-orange-500 text-white">
                                          {word.substring(
                                            0,
                                            searchKeywords?.length
                                          )}
                                        </span>
                                        <span>
                                          {word.substring(
                                            searchKeywords?.length
                                          )}
                                        </span>{" "}
                                      </span>
                                    ) : (
                                      <span>{word} </span>
                                    )}
                                  </span>
                                );
                              })}
                            </p>
                          </div>
                          <span className="text-xs text-gray-500 leading-none">
                            {formatTime(item?.createdAt)}
                          </span>
                        </div>
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${usersChatDetails?.participants[1]?.profilePic}`}
                          className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-100"
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatModal;