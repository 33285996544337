import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { IoIosMore, IoMdMore } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import PopupCategory from "./PopupCategory";
import CategoryDeleteModal from "./CategoryDeleteModal";
import Pagination from "../../Common/Pagination";
import IsLoadingHOC from "../../Common/IsLoadingHOC";

const Category = (props) => {
  const { setLoading } = props;

  const [serviceData, setserviceData] = useState([]);
  const [CategoriesData, setCategoriesData] = useState([]);
  const [searchKeywords, setsearchKeywords] = useState("");
  const [activeActionDropdown, setActiveActionDropdown] = useState("");
  const [showPopupCategory, setshowPopupCategory] = useState(false);
  const [showCategoryDeleteModal, setshowCategoryDeleteModal] = useState(false);
  const [CategoryDeleteId, setCategoryDeleteId] = useState("");
  const [CategoryDetails, setCategoryDetails] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(5);
  const [totalPosts, settotalPosts] = useState(0);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const fetchCategory = async () => {
    setLoading(true);
    await authAxios()
      .get(`/services/get-all-Category`)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setCategoriesData(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.reponse?.data?.message);
      });
  };

  const fetchServices = async () => {
    setLoading(true);
    await authAxios()
      .get("/services/get-all-services")
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setserviceData(resData?.data);
          const arr=[]
         resData.data.map((item)=>arr.push({label:item?.serviceName,value:item?.serviceName}))
         console.log(arr)
         setCategoriesData(arr)
         
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleActiveActionDropdown = (item) => {
    setActiveActionDropdown((prevItem) => (prevItem === item ? null : item));
  };

  const handleAddService = async (item) => {
    const payload = item;
    setLoading(true);
    await authAxios()
      .post("/services/create-service", payload)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        console.log(resData);
        if (resData.status == 1) {
           toast.success(resData.message);
         
          setshowPopupCategory(false);
        //  fetchCategory();
          fetchServices();
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleDeleteCategory = async (id) => {
    setCategoryDeleteId(id);
    setshowCategoryDeleteModal(true);
    if (showCategoryDeleteModal === true) {
      setLoading(true);
      await authAxios()
        .delete(`/services/delete-service/${CategoryDeleteId}`)
        .then((response) => {
          setLoading(false);
          if (response.data.status == 1) {
            setshowCategoryDeleteModal(false);
          //  fetchCategory();
            fetchServices();
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleUpdateCategory = async (item) => {
    setshowPopupCategory(true);
    setCategoryDetails(item);

    if (showPopupCategory === true) {
      const payload = {
        id: item?.id,
        serviceName: item?.serviceName,
        category: item?.category,
      };
      setLoading(true);
      await authAxios()
        .put(`/services/update-service/${item.id}`, payload)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData.status == 1) {
            
            setshowPopupCategory(false);
            setCategoryDetails([]);
            setActiveActionDropdown("");
        //    fetchCategory();
            fetchServices();
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleSearchService = async (value) => {
    if (value) {
      const payload = {
        query: value,
      };
      setsearchKeywords(value);
      // setLoading(true)
      await authAxios()
        .get("/services/search-services", { params: payload })
        .then((response) => {
          const resData = response?.data;
          //  setLoading(false);
          if (resData?.status === 1) {
            setserviceData(resData?.data);
          } else {
            toast.error(resData?.message);
            setserviceData([]);
          }
        })
        .catch((error) => {
          //    setLoading(false)
          toast.error(error?.response?.data?.message);
          setserviceData([]);
        });
    } else {
    //  fetchCategory();
      fetchServices();
      setsearchKeywords("");
    }
  };

  useEffect(() => {
    fetchServices();
  //  fetchCategory();
  }, []);

  console.log("serviceData",serviceData)

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto pb-[65px]">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            Categories & Services
          </h1>
        </div>

        <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse ">
          <div className="filter--search flex">
            <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[45px] min-h border border-bordergray">
              <img
                src="/assets/images/search-icon.svg"
                className="search--icon"
              />
              <input
                type="text"
                placeholder="Search here"
                value={searchKeywords}
                className="input--text"
                onChange={(e) => handleSearchService(e.target.value)}
              />
            </div>
          </div>

          <div className="flex gap-[5px] gap-y-[10px] flex-wrap w-full justify-end">
            <button className="flex items-center max-w-[90px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
              Total: {serviceData?.length || 0}
            </button>

            <button
              onClick={() => setshowPopupCategory(true)}
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
            >
              <FiPlus />
              Add 
            </button>
          </div>
        </div>

        <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table pb-0">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-lightcolor border-b border-textcolor">
              <tr>
               

                <th scope="col" className="p-4 font-[400]">
                  S.No.
                </th>

              
                <th scope="col" className="px-6 py-3 font-[400]">
                  Category 
                </th>
               
                <th scope="col" className="px-6 py-3 font-[400]">
                  Service 
                </th>

                <th scope="col" className="px-3 py-3 w-[50px] font-[400]">
                  Acton
                </th>
              </tr>
            </thead>
            <tbody>
              {serviceData &&
                serviceData?.length > 0 &&
                serviceData?.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    

                    <td className="w-4 p-4 max-w-[50px]">
                      <div className="flex items-center">
                      {index+1}
                        
                      </div>
                    </td>

                  {/*   <th
                      scope="row"
                      className="px-6 py-4 font-medium text-textcolor whitespace-nowrap min-w-[100px]"
                    >
                      <img
                        src="/assets/images/category-1.png"
                        className="category--image w-[46px] h-[46px] rounded-[5px] object-cover object-center"
                      />
                    </th>
                    */}
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-textcolor max-w-[200px]"
                    >
                      {item?.serviceName}
                    </th>

                
                    <td className="px-6 py-4 font-medium text-textcolor max-w-[200px]">
                      <ul>
                        {item?.category.map((cat) => (
                          <li key={cat._id}>{cat?.label}</li>
                        ))}
                      </ul>
                    </td>

                    <td className="px-3 py-4 max-w-[50px]">
                      <div className="action--btn flex gap-[10px] relative">
                        <div
                          className="more--icon cursor-pointer w-[30px] h-[30px] bg-lightgray rounded-[5px] flex items-center justify-center"
                          onClick={() => handleActiveActionDropdown(item)}
                        >
                          <IoMdMore />
                        </div>
                        {activeActionDropdown === item && (
                          <div className="action--dropdown">
                            <ul>
                              <li onClick={() => handleUpdateCategory(item)}>
                                <FaRegEdit /> <span>Edit</span>
                              </li>

                              <li
                                onClick={() => handleDeleteCategory(item?._id)}
                                className="!text-red-500"
                              >
                                <RiDeleteBinLine /> <span>Delete</span>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

        </div>
          {/* end table 
            <Pagination
              currentPage={currentPage}
              totalPosts={totalPosts}
              paginate={paginate}
              postsPerPage={postsPerPage}
            />
          */}
          {/* end pagination */}

        {showPopupCategory && (
          <PopupCategory
            CategoryDetails={CategoryDetails}
            setshowPopupCategory={setshowPopupCategory}
            CategoriesData={CategoriesData}
            handleAddService={handleAddService}
            setActiveActionDropdown={setActiveActionDropdown}
            handleUpdateCategory={handleUpdateCategory}
            setCategoryDetails={setCategoryDetails}
          />
        )}

        {showCategoryDeleteModal && (
          <CategoryDeleteModal
            setshowCategoryDeleteModal={setshowCategoryDeleteModal}
            setActiveActionDropdown={setActiveActionDropdown}
            handleDeleteCategory={handleDeleteCategory}
          />
        )}
      </div>
    </div>
  );
};

export default IsLoadingHOC(Category);
