import React from "react";
import { IoCloseSharp } from "react-icons/io5";

const TaskConfirmationModel = ({
  handleMarkTaskIncomplete,
  handleMarkTaskComplete,
  setshowTaskConfirmationModel,
  showTaskConfirmationModel,
}) => {
  console.log("show", showTaskConfirmationModel);

  return (
    <div
      className="z-[1] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full h-full"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 modal--popupservice">
            <div className="">
              <div className="pophead flex justify-between items-start mb-[10px]">
                <h2 className="font-semibold text-[20px]">
                  {showTaskConfirmationModel.action ==
                    "handleMarkTaskComplete" ? (
                    <h2>
                      {" "}
                      Are you sure you want to release funds to the Hustler
                      Account, and will the job status be marked as completed{" "}
                    </h2>
                  ) : (
                    <h2>
                      Are you sure you want to refund to the Director
                      Account, and will the job status be marked as closed{" "}
                    </h2>
                  )}
                </h2>

                <div
                  className="close cursor-pointer text-[20px]"
                  // onClick={handleclose}
                  //onClick={e => {setshowAddCategory(false);setActiveActionDropdown('') } }
                  onClick={() =>
                    setshowTaskConfirmationModel((prev) => ({
                      ...prev,
                      model: false,
                      action: "",
                    }))
                  }
                >
                  <IoCloseSharp />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6 gap-[10px] justify-end">
            <button
              type="button"
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
              onClick={
                showTaskConfirmationModel.action == "handleMarkTaskComplete"
                  ? handleMarkTaskComplete
                  : handleMarkTaskIncomplete
              }
            //  onClick={()=>showTaskConfirmationModel.action()}

            //   onClick={handleclose}
            // onClick={() => setshowAddCategory(false)}

            //   onClick={handleMarkTaskIncomplete?handleMarkTaskIncomplete:handleMarkTaskComplete}
            >
              Yes
            </button>

            <button
              type="button"
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
              onClick={() =>
                setshowTaskConfirmationModel((prev) => ({
                  ...prev,
                  model: false,
                  action: "",
                }))
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskConfirmationModel;
