import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";
import { MdChat, MdOutlineCleaningServices } from "react-icons/md";
import { FaAngleDown, FaChevronLeft } from "react-icons/fa6";
import { BiSolidDashboard } from "react-icons/bi";
import { FaTasks } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { IoMenuOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const SideBar = () => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const location = useLocation();
  const { fname, lname, userType } = useSelector((state) => state.auth.user);

  const firstLetter = fname ? fname.charAt(0).toUpperCase() : '';

  const handleActiveSubMenu = (item) => {
    setActiveSubMenu((prevItem) => (prevItem === item ? null : item));
  };

  return (
    <aside
      className={`mt-[65px] bg-white text-gray-50 w-[266px] flex-shrink-0 flex flex-col h-screen sticky top-[0px] px-[15px] py-[20px] sidebar--menu ${activeSubMenu === "togglesidebar" ? "active--toggle" : ""
        }`}
    >
      <div className="top--logobar">
        <div
          className="toggle--bar"
          onClick={() => handleActiveSubMenu("togglesidebar")}
        >
          <FaChevronLeft className={`text-white bg-black rounded-[50px] p-2 w-[32px] h-[32px] duration-200 ${activeSubMenu === 'togglesidebar' ? 'rotate-[180deg]' : ''}`} />
        </div>
      </div>

      <div className="sidebar--menu--items--lists overflow-y-auto overflow-x-clip over--scroller">

        <div className="login--details mb-[25px] pb-[25px]">

          <h3 className="single--name uppercase">{firstLetter}</h3>

          <h2 className="user--name">{fname} {lname}</h2>
          <p className="user--role">Role: {userType}</p>


          {/* {activeSubMenu === "togglesidebar" ? (

            <>
              <h3 className="single--name">A</h3>
            </>

          ) : (
            <>
              <h2 className="user--name">Admin Name</h2>
              <p className="user--role">Role: Admin</p>
            </>
          )
          } */}
        </div>

        <div className="management--menu pb-[24px] mb-[24px]">

          <p className="label--menu hide--content">Management</p>

          <ul className="flex flex-col gap-[0px] menu--bar--list">
            <li>
              <Link
                to="/"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/news-letter"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/news-letter' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>News Letter</span>
              </Link>
            </li>
            {/* 
            <li>
              <Link
                to="#"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>View Admin</span>
              </Link>
            </li>
            */}

          </ul>


        </div>

        {/* and management menu */}

        <div className="management--menu pb-[24px] mb-[24px]">

        {/*  <p className="label--menu hide--content">Services</p>*/} 

      {/* */}    <ul className="flex flex-col gap-[0px] menu--bar--list">
           <li>
              <Link
                to="/all-categories"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/all-categories' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Categories & Services</span>
              </Link>
            </li>


            {/* 

            <li>
              <Link
                to="/all-services"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/all-services' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Add Services</span>
              </Link>
            </li>
            */}
           

            <li>
              <Link
                to="/sensitive-words"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/sensitive-words' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Sensitive Words</span>
              </Link>
            </li>

            <li>
              <Link
                to="/all-notification"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/all-notification' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Notification</span>
              </Link>
            </li>

      {/*       <li>
              <Link
                to="/payment"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/payment' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Payments</span>
              </Link>
            </li>

          */}

          

          </ul>


        </div>

        {/* and Services menu */}

        <div className="management--menu pb-[24px] mb-[24px]">

          <p className="label--menu hide--content">Users</p>

          <ul className="flex flex-col gap-[0px] menu--bar--list">
            <li>
              <Link
                to="/all-users"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/all-users' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Users</span>
              </Link>
            </li>

            <li>
              <Link
                to="/jobs"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/jobs' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Jobs</span>
              </Link>
            </li>

            <li>
              <Link
                to="/chats"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/chats' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Chats</span>
              </Link>
            </li>

          </ul>


        </div>


        <div className="management--menu pb-[24px] mb-[24px]">

          <p className="label--menu hide--content">Accounts</p>

          <ul className="flex flex-col gap-[0px] menu--bar--list">
            <li>
              <Link
                to="/payment-details"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/payment-details' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Payment Details</span>
              </Link>
            </li>

            <li>
              <Link
                to="/subscription-details"
                className={`px-[5px] py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-white rounded-[5px] text-textcolor ${location.pathname === '/subscription-details' ? 'active' : ''}`}
              >
                <img src="/assets/images/home-icon.svg" className="menu--icon" />
                <span>Subscription Details</span>
              </Link>
            </li>

          

          </ul>


        </div>

      </div>



      {/* and Services menu */}

      {/* <ul className="flex flex-col gap-[10px] menu--bar--list">
        <li>
          <Link
            to="/"
            className="px-3 py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-black2 rounded-[5px] text-white "
          >
            <BiSolidDashboard className="menu--icon" />
            <span>Dashboard</span>
          </Link>
        </li>

        <li>
          <Link
            className="px-3 py-3 transition-colors duration-200 relative block text-black flex items-center justify-between gap-[5px] bg-black2 rounded-[5px] text-white"
            onClick={() => handleActiveSubMenu("services")}
          >
            <div className="flex items-center">
              <MdOutlineCleaningServices
                className={`menu--icon ${activeSubMenu === "togglesidebar" ? "mr-[0px]" : "mr-[5px]"
                  }`}
              />
              <span>Categories</span>
            </div>

            <FaAngleDown
              className={`downarrow-menu duration-100 ${activeSubMenu === "services"
                ? "rotate-[0deg]"
                : "rotate-[-90deg]"
                }`}
            />
          </Link>

          {activeSubMenu === "services" && (
            <ul className="mt-[5px] dropdown--items">
              <li>
                <Link
                  to="/all-categories"
                  className="px-3 py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-black2 rounded-[5px] text-white mb-[5px]"
                >
                  Categories
                </Link>
              </li>
              <li>
                <Link
                  to="/all-services"
                  className="px-3 py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-black2 rounded-[5px] text-white mb-[5px]"
                >
                  Services
                </Link>
              </li>
            </ul>
          )}
        </li>

        <li>
          <Link
            to="/tasks"
            className="px-3 py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-black2 rounded-[5px] text-white "
          >
            <FaTasks className="menu--icon" />
            <span>Task</span>
          </Link>
        </li>

        <li>
          <Link
            to="/all-users"
            className="px-3 py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-black2 rounded-[5px] text-white "
          >
            <FiUsers className="menu--icon" />
            <span>Users</span>
          </Link>
        </li>
        <li>
          <Link
            to="/chats"
            className="px-3 py-3 transition-colors duration-200 relative block text-black flex items-center gap-[5px] bg-black2 rounded-[5px] text-white "
          >
            <MdChat className="menu--icon" />
            <span>Chats</span>
          </Link>
        </li>
      </ul> */}
    </aside>
  );
};

export default SideBar;
