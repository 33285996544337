import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../Redux/Reducers/authSlice";

const CommonHome = () => {
  const userType = useSelector((state) => state.auth?.user?.userType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userType != "ADMIN") {
      dispatch(logout());
      navigate("/login");
    } else {
      navigate("/login");
    }
  }, []);
  return <div></div>;
};

export default CommonHome;
