import React, { useEffect, useState } from "react";
import { authAxios } from "../../config/config";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import PopupCheatWords from "./PopupCheatWords";
import { IoIosMore, IoMdMore } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import DeleteCheatWordModal from "./DeleteCheatWordModal";
import { setReportFormatDate } from "../../Helper/helper";
import Pagination from "../../Common/Pagination";
import { TbUvIndex } from "react-icons/tb";

const CheatWordsList = (props) => {
  const { setLoading } = props;
  const [activeActionDropdown, setActiveActionDropdown] = useState("");
  const [searchKeywords, setsearchKeywords] = useState("");
  const [sensitiveWords, setsensitiveWords] = useState([]);
  console.log(sensitiveWords,"sensitiveWords.....")
  const [showmodel, setshowmodel] = useState({
    addmodel: "",
    deletemodel: "",
  });

  const [sensitiveWordsDetails, setsensitiveWordsDetails] = useState({
    id: "",
    word: "",
  });

  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(25);
  const [totalPosts, settotalPosts] = useState(0);
  const [ongoingPagination, setongoingPagination] = useState("all");
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const handleActiveActionDropdown = (item) => {
    setActiveActionDropdown((prevItem) => (prevItem === item ? null : item));
  };

  const handleDeleteSensitiveWord = async (item) => {
    if (showmodel.deletemodel == true) {
      setLoading(true);
      await authAxios()
        .delete(
          `sensitiveword/delete-sensitive-words/${sensitiveWordsDetails?.id}`
        )
        .then((response) => {
          const resData = response.data;
          setLoading(false);
          if (resData.status == 1) {
            toast.success(resData?.message);

            setshowmodel({
              addmodel: false,
              deletemodel: false,
            });
            setActiveActionDropdown("");
            setsensitiveWordsDetails({
              id: "",
              word: "",
            });
            fetchSensitiveWords();
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    } else {
      setsensitiveWordsDetails((prev) => ({
        ...prev,
        id: item?._id,
        word: item?.word,
      }));
      setshowmodel((prev) => ({
        ...prev,
        addmodel: false,
        deletemodel: true,
      }));
    }
  };

  const handleEditSensitiveWord = async (item) => {
    if (showmodel.addmodel == true) {
      const payload = {
        word: item,
      };
      setLoading(true);
      await authAxios()
        .put(
          `sensitiveword/update-sensitive-words/${sensitiveWordsDetails?.id}`,
          payload
        )
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData?.status == 1) {
            toast.success(resData?.message);

            setshowmodel({
              addmodel: false,
              deletemodel: false,
            });
            setActiveActionDropdown("");
            setsensitiveWordsDetails({
              id: "",
              word: "",
            });
            fetchSensitiveWords();
          } else {
            toast.error(resData?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response.data.message);
        });
    } else {
      setsensitiveWordsDetails((prev) => ({
        ...prev,
        id: item._id,
        word: item.word,
      }));
      setshowmodel((prev) => ({
        ...prev,
        addmodel: true,
        deletemodel: false,
      }));
    }
  };

  const handleaddSensitiveWord = async (item) => {
    const payload = {
      word: item.toLowerCase(),
    };
    setLoading(true);
    await authAxios()
      .post("/sensitiveword/create-sensitive-words", payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData?.status == 1) {
          toast.success(resData?.message);

          setshowmodel({
            addmodel: false,
            deletemodel: false,
          });
          setActiveActionDropdown("");
          setsensitiveWordsDetails({
            id: "",
            word: "",
          });
          fetchSensitiveWords();
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchSensitiveWords = async () => {
    setLoading(true);
    await authAxios()
      .get(
        `/sensitiveword/get-all-sensitive-words?page=${currentPage}&limit=${postsPerPage}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData?.status == 1) {
          settotalPosts(resData.data.total);
          setsensitiveWords(resData?.data?.allWords);
        } else {
          toast.error(resData?.status);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSearch = async (value) => {
    if (value) {
      handleSearching(1, value);
      setsearchKeywords(value);
      setcurrentPage(1);
      setongoingPagination("search-words");
    } else {
      fetchSensitiveWords();
      setsearchKeywords("");
      setongoingPagination("all");
    }
  };

  const handleSearching = async (number, value) => {
    await authAxios()
      .get(
        `/sensitiveword/search-sensitive-words?page=${
          number || currentPage
        }&limit=${postsPerPage}`,
        {
          params: { query: value || searchKeywords },
        }
      )
      .then((response) => {
        const resData = response?.data;
        console.log(response, "response...........");
        // if (resData.status === 1) {
        if (resData?.data && resData?.data?.words?.length > 0) {
          setsensitiveWords(resData?.data?.allWords);
          settotalPosts(resData?.data?.total);
        } else {
          //  toast.info("No sensitive words found");
          setsensitiveWords([]);
        }

        // } else {
        //   toast.error(resData.message);
        //   //setserviceData([]);
        // }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        //setserviceData([]);
      });
  };

  useEffect(() => {
    if (ongoingPagination == "all") {
      fetchSensitiveWords();
    } else if (ongoingPagination == "search-words") {
      handleSearching();
    }
  }, [currentPage]);

  console.log(sensitiveWords);

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto pb-[65px]">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            Sensitive Words
          </h1>
        </div>

        <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse ">
          <div className="filter--search flex">
            <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[45px] min-h border border-bordergray">
              <img
                src="/assets/images/search-icon.svg"
                className="mr-[5px] search--icon"
              />
              <input
                type="text"
                placeholder="Search here"
                value={searchKeywords}
                className="input--text"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>

          <div className="flex gap-[5px] gap-y-[10px] flex-wrap w-full justify-end">
            <button className="flex items-center max-w-[140px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
              Total Words: {totalPosts || 0}
            </button>

            <button
              onClick={() =>
                setshowmodel((prev) => ({ ...prev, addmodel: true }))
              }
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
            >
              <FiPlus />
              Add Words
            </button>
          </div>
        </div>

        <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-lightcolor border-b border-textcolor">
              <tr>
                {/* 
                <th scope="col" className="p-4 font-[400]">
                  <div className="flex items-center custom--checkbox">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <span className="checkmark--custom"></span>
                  </div>
                </th>
                */}

                <th scope="col" className="p-4 w-[50px] font-[400] w-[90px]">
                  S.No.
                </th>

                <th scope="col" className="px-6 py-3 w-[350px] font-[400]">
                  {" "}
                  Word
                </th>
                <th scope="col" className="px-6 py-3 font-[400] min-w-[200px]">
                  Created at
                </th>
                <th scope="col" className="px-3 py-3 w-[50px] font-[400]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {sensitiveWords && sensitiveWords.length > 0 ? (
                sensitiveWords.map((item, index) => (
                  <tr
                    key={TbUvIndex}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-textcolor max-w-[50px]"
                    >
                      {index + 1}
                    </td>

                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-textcolor max-w-[150px] text-transform: capitalize"
                    >
                      {item?.word}
                    </th>

                    <td className="px-6 py-4 text-textcolor max-w-[150px]">
                      {setReportFormatDate(item.createdAt)}
                    </td>

                    <td className="px-6 py-4 max-w-[50px]">
                      <div className="action--btn flex gap-[10px] relative">
                        <div
                          className="more--icon cursor-pointer w-[30px] h-[30px] bg-lightgray rounded-[5px] flex items-center justify-center"
                          onClick={() => handleActiveActionDropdown(item)}
                        >
                          <IoMdMore />
                        </div>
                        {activeActionDropdown === item && (
                          <div className="action--dropdown z-[1]">
                            <ul>
                              <li onClick={() => handleEditSensitiveWord(item)}>
                                <FaRegEdit /> <span>Edit</span>
                              </li>
                              <li
                                onClick={() => handleDeleteSensitiveWord(item)}
                                className="!text-red-500"
                              >
                                <RiDeleteBinLine /> <span>Delete</span>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
{sensitiveWords.length > 0 ?
        <Pagination
          currentPage={currentPage}
          totalPosts={totalPosts}
          paginate={paginate}
          postsPerPage={postsPerPage}
          length={sensitiveWords.length}
        />
        :""}

        {/* end pagination */}

        {showmodel.addmodel && (
          <PopupCheatWords
            setshowmodel={setshowmodel}
            setActiveActionDropdown={setActiveActionDropdown}
            handleaddSensitiveWord={handleaddSensitiveWord}
            sensitiveWordsDetails={sensitiveWordsDetails}
            setsensitiveWordsDetails={setsensitiveWordsDetails}
            handleEditSensitiveWord={handleEditSensitiveWord}
          />
        )}

        {showmodel.deletemodel && (
          <DeleteCheatWordModal
            setshowmodel={setshowmodel}
            setsensitiveWordsDetails={setsensitiveWordsDetails}
            setActiveActionDropdown={setActiveActionDropdown}
            handleDeleteSensitiveWord={handleDeleteSensitiveWord}
          />
        )}
      </div>
    </div>
  );
};

export default IsLoadingHOC(CheatWordsList);
