import React, { useEffect, useState } from "react";
import IsLoadingHOC from "../Common/IsLoadingHOC";
import { IoSearchOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import Select from "react-select";
import CardOne from "../Common/CardOne";
import ChartThree from "../Common/ChartThree";
import ChartOne from "../Common/ChartOne";
import RecentPayments from "../Common/RecentPayments";

import { authAxios } from "../config/config";
import { toast } from "react-toastify";
import IsLoggedinHOC from "../Common/IsLoggedInHOC";
import RecentJobs from "../Common/RecentJobs";

const Home = (props) => {
  const { setLoading } = props;

  const [dashboadDetails, setdashboadDetails] = useState([]);
  const [circleDetails, setcircleDetails] = useState([]);
  
  const fetchtask = async (task, number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/task/all-task/${'all'}?page=${
          1
        }&limit=${5}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData?.status == 1) {
         

          fetchdashboardDetail(resData?.count?.total)
          
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchdashboardDetail = async (totalTask) => {
    setLoading(true);
    await authAxios()
      .get(`/dashboard/count`)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        console.log(resData)
        if (resData?.status == 1) {
          setcircleDetails([
            // totalTask,
            resData.data?.totalCompletedTasks,
            resData.data?.totalInProgressTasks,
            resData.data?.totalOpenTasks,

          ]);

          setdashboadDetails(resData?.data);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

   

  useEffect(() => {
    
   // fetchdashboardDetail();
    fetchtask()
  }, []);

  return (
    <div className="w-[100%] p-5 bg-lightgray">
      <h2 className="text-2xl font-semibold mb-4"></h2>
      <div className="w-[100%] mx-auto py-[20px]">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:gap-7.5">
          {/* <CardOne text="Total Revenue" percent="$230" Icon="revenue" />
          <CardOne text="Total Profit" percent="$150" Icon="shoping" /> */}
          <CardOne
            text="Total Users"
            percent={dashboadDetails?.totalUsers}
            Icon="user"
          />

          <CardOne
            text="Total Completed Jobs"
            percent={dashboadDetails?.totalCompletedTasks}
            Icon="complete"
          />
          <CardOne
            text="Total Ongoing Jobs"
            percent={dashboadDetails?.totalInProgressTasks}
            Icon="ongoing"
          />
          <CardOne
            text="Total Open Jobs"
            percent={dashboadDetails?.totalOpenTasks}
            Icon="Open"
          />
        </div>
        <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
          <RecentPayments />
          <ChartThree circleDetails={circleDetails}/>
          
        </div>
        
      </div>
    </div>
  );
};

export default IsLoadingHOC(IsLoggedinHOC(Home));