import React, { useEffect, useState } from "react";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import { IoCloseSharp } from "react-icons/io5";

const PopupCheatWords = ({
  handleaddSensitiveWord,
  handleEditSensitiveWord,
  setActiveActionDropdown,
  sensitiveWordsDetails,
  setshowmodel,
  setsensitiveWordsDetails,
}) => {
  const [sensitiveWords, setsensitiveWords] = useState("");

  useEffect(() => {}, []);

  const handleclose = () => {
    setshowmodel({
      addmodel: false,
      deletemodel: false,
    });
    setActiveActionDropdown("");
    setsensitiveWordsDetails({
      id: "",
      word: "",
    });
  };

  const handleAddword = (e) => {
    e.preventDefault();

    handleaddSensitiveWord(sensitiveWords);
  };

  useEffect(() => {
    setsensitiveWords(sensitiveWordsDetails?.word);
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();
    handleEditSensitiveWord(sensitiveWords);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <form
          onSubmit={sensitiveWordsDetails?.id ? handleUpdate : handleAddword}
          className="flex min-h-full justify-center p-4 text-center items-center sm:p-0"
        >
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 modal--popupservice">
              <div className="">
                <div className="pophead flex justify-between items-center mb-[10px]">
                  {!sensitiveWordsDetails?.id == "" ? (
                    <h2 className="font-semibold text-[20px]">
                      Update your word
                    </h2>
                  ) : (
                    <h2 className="font-semibold text-[20px]">
                      Add new word
                    </h2>
                  )}
                  <div
                    className="close cursor-pointer text-[20px]"
                    onClick={handleclose}
                  >
                    <IoCloseSharp />
                  </div>
                </div>
                <div className="mb-[10px]">
                  <label className="block text-sm leading-6 text-gray-900">
                    Word Name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="serviceName"
                      required
                      value={sensitiveWords}
                      onChange={(e) => setsensitiveWords(e.target.value)}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-[10px] justify-end">
              {!sensitiveWordsDetails?.id == "" ? (
                <button
                  type="submit"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"

                  // onClick={() => handleaddCategory(CategoryName)}
                >
                  Update
                </button>
              ) : (
                <button
                  type="submit"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  // onClick={() => handleaddCategory(CategoryName)}
                >
                  Add
                </button>
              )}

              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={handleclose}
                // onClick={() => setshowAddCategory(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IsLoadingHOC(PopupCheatWords);
