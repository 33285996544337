import React, { useEffect, useState } from "react";
import { authAxios } from "../../config/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import {
  BudgetFormat,
  getFormatedDate,
  setReportFormatDate,
} from "../../Helper/helper";
import { Link } from "react-router-dom";
import { FaChevronDown, FaEye } from "react-icons/fa";
import ViewTaskModal from "./ViewTaskModal";
import { IoMdMore } from "react-icons/io";
import Pagination from "../../Common/Pagination";
import TaskConfirmationModel from "./TaskConfirmationModel";
  
  
const AllTask = (props) => {
  const { setLoading } = props;

  const [Task, setTask] = useState([]);
  const [showTaskModel, setshowTaskModel] = useState(false);
  const [taskDetails, settaskDetails] = useState([]);
  const [searchKeywords, setsearchKeywords] = useState("");
  const [currentTaskStatus, setcurrentTaskStatus] = useState("all");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(25);
  const [payments, setPayments] = useState([]);
  const [totalPosts, settotalPosts] = useState(0);
  const [ongoingPagination, setongoingPagination] = useState("all");
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const [checkUser, setcheckUser] = useState("");
  const [activeActionDropdown, setActiveActionDropdown] = useState("");
  
  
  //const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  

  const [showTaskConfirmationModel, setshowTaskConfirmationModel] = useState({
    model: false,
    action: "",
  });

  const fetchtask = async (task, number) => {
    setLoading(true);
    await authAxios()
      .get(
        `/task/all-task/${task || currentTaskStatus}?page=${
          number || currentPage
        }&limit=${postsPerPage}&start_date=${startDate}&end_date=${endDate}&title=${searchKeywords}`
      )
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData?.status == 1) {
          settotalPosts(resData.count.total);
          setTask(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  
	
	const fetchCompleteTasks = async (flag,task,number) => {
    setcurrentPage(1)
	  try {
        console.log("End Date ", endDate); 
        console.log("Start Date ", startDate);
   
      const response = await authAxios().get(`/task/all-task/${task || currentTaskStatus}?page=${
          number || currentPage
        }&limit=${postsPerPage}`, {
      params: {
        
        start_date: flag? startDate : '',
        end_date: flag? endDate : '',
        
      }
    });
    //const response = await authAxios().get(url);
    const resData = response.data; 
	 setTask(resData?.data);
   settotalPosts(resData?.count?.total);
    //console.log(resData, "fetchCompleteTasks12345543");

  } catch (error) {
    console.error("An error occurred while fetching completed tasks:", error);
  }
};
	
   const handleStartDate = (e)=> {
       //setStartDate(e.target.value);
   
   }
    const handleEndDate = (e) => {
      //setEndDate(e.target.value);
	
  };
  
    useEffect(() => {
		fetchtask();
    // setongoingPagination("datefilter")
    setcurrentPage(1)
     }, [startDate,endDate]);
  

  const handleShowfilters = () => {
    console.log("Applying filters...");
       fetchCompleteTasks();
  };	  
	// Example of calling the function
	
  const handleTaskChange = (e) => {
    const item = e.target.value;

    fetchtask(item, 1);
    setcurrentTaskStatus(item);
    setcurrentPage(1);
    setongoingPagination("all");
  };

  const handlePayout = async (taskDetails) => {
    try {
      setLoading(true); // Show loader
      console.log(taskDetails, "taskDetailstaskDetails");
      const payload = {
        amount: taskDetails.finalPrice,
        currency: "cad",
        destination: taskDetails?.hustlerStripeId?.stripeAccountId,
        transfer_group: taskDetails.stripeTransferGroup,
      };

      const response = await authAxios().post(
        "/stripe/create-account-transfer-payout",
        payload
      );
      setLoading(false);

      if (response?.data?.status == 1) {
        toast.success("Transfer created successfully");
      } else {
        toast.error(response?.data?.message || "Failed to create transfer.");
      }
    } catch (error) {
      setLoading(false); // Hide loader in case of error
      console.error("Error creating transfer:", error);
      toast.error(`Error creating transfer: ${error.message}`);
    }
  };

  const handleTransferpaymentToHustler = async () => {
    try {
      setLoading(true);

      const handleCheckLatestCharge = payments.filter(
        (item) => item?.task?._id === taskDetails._id
      );
      const payload = {
        amount: taskDetails.finalPrice,
        currency: "cad",
        destination: taskDetails?.hustlerId?.stripeAccountId,
        latest_charge: handleCheckLatestCharge[0].intent.latest_charge,
        transfer_group: taskDetails.stripeTransferGroup,
      };

      const formData = new URLSearchParams();
      formData.append("amount", payload.amount);
      formData.append("currency", payload.currency);
      formData.append("destination", payload.destination);
      formData.append("source_transaction", payload.latest_charge);
      formData.append("transfer_group", payload.transfer_group);
      const response = await authAxios().post(
        "/stripe/create-account-transfer",
        formData
      );
      settaskDetails([]);
      fetchtask();
      setshowTaskConfirmationModel((prev) => ({
        ...prev,
        model: false,
        action: "",
      }));
      fetchPayments();
      toast.success(response.data.message);
      setLoading(false);
    } catch (error) {
      fetchPayments();
      fetchtask();
      setshowTaskConfirmationModel((prev) => ({
        ...prev,
        model: false,
        action: "",
      }));
      setLoading(false);
      // toast.error(error.response.data.message);
      console.error("Error transferring payment:", error);
    }
  };

  const handleMarkTaskComplete = async () => {
    setshowTaskModel(false);
    setActiveActionDropdown("");

    setshowTaskConfirmationModel((prev) => ({
      ...prev,
      model: true,
      action: "handleMarkTaskComplete",
    }));

    if (showTaskConfirmationModel.model == true) {
      setLoading(true);

      await authAxios()
        .put(`/bookhustler/decline-director-decision/${taskDetails._id}`)
        .then((response) => {
          const resData = response.data;

          if (resData.status == 1) {
            setLoading(false);
            /*
            
            */

            toast.success(resData.message);
            handleTransferpaymentToHustler();
          } else {
            toast.error(resData.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleMarkTaskIncomplete = async () => {
    setshowTaskModel(false);
    setActiveActionDropdown("");

    setshowTaskConfirmationModel((prev) => ({
      ...prev,
      model: true,
      action: "handleMarkTaskIncomplete",
    }));

    if (showTaskConfirmationModel.model == true) {
      await authAxios()
        .put(`/bookhustler/accept-director-decision/${taskDetails._id}`)
        .then((response) => {
          const resData = response.data;

          if (resData.status == 1) {
            setshowTaskConfirmationModel((prev) => ({
              ...prev,
              model: false,
              action: "",
            }));
            settaskDetails([]);
            fetchtask();
            // toast.success(resData.message);
          } else {
            setshowTaskConfirmationModel((prev) => ({
              ...prev,
              model: false,
              action: "",
            }));
            settaskDetails([]);
            fetchtask();
            //    toast.success(resData.message);
          }
        })
        .catch((error) => {
          setshowTaskConfirmationModel((prev) => ({
            ...prev,
            model: false,
            action: "",
          }));
          settaskDetails([]);
          fetchtask();
          //  toast.error(error.response.data.message);
        });
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await authAxios().get(
        "/stripe/list-stripe-payment-intents"
      );
      console.log(response.data);
      setPayments(response.data.data);
    } catch (error) {
      console.error("Error fetching payments:", error.message);
    }
  };

  const fetchTaskDetails = async (id) => {
    setLoading(true);
    await authAxios()
      .get(`/task/get-specific-task/${id}`)
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData?.status == 1) {
          setshowTaskModel(true);
          settaskDetails(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSearch = async (value) => {
    if (value) {
      setcurrentPage(1);
      // handleSearching(1, value);
      fetchtask();
      setsearchKeywords(value);
      setongoingPagination("jobs-searching");
    } else {
      fetchtask(currentTaskStatus);
      setongoingPagination("all");
      setsearchKeywords("");
    }
    /*
    if (value) {
      const payload = {
        query: value,
      };
      setsearchKeywords(value);
      await authAxios()
        .get(`/adminsearch/search-task`, { params: payload })
        .then((response) => {
          const resData = response?.data;
          if (resData?.status == 1) {
            setTask(resData?.data);
          } else {
            toast.error(resData?.error);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      fetchtask();
      setsearchKeywords("");
    }
    */
  };

  const handleSearching = async (number, value) => {
    const payload = {
      query: value || searchKeywords,
    };
    await authAxios()
      .get(
        `/adminsearch/search-task?page=${
          number || currentPage
        }&limit=${postsPerPage}`,
        { params: payload }
      )
      .then((response) => {
        const resData = response?.data;
        if (resData?.status == 1) {
          console.log(resData);
          setTask(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.error);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleActiveActionDropdown = (item) => {
    setActiveActionDropdown((prevItem) => (prevItem === item ? null : item));
  };

  const UnblockTask = async (id) => {
    console.log("if",id)
    setLoading(true)
    await authAxios()
      .put(`/task/tasks-unblock-by-admin`,{taskId:id})
      .then((response) => {
        fetchtask()
        setLoading(false)
        toast.success(response.data.data.message);
       
        setActiveActionDropdown("")
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    fetchPayments();
	// fetchCompleteTasks();
	//handleClearFilters();
  }, []);

  console.log("Task", Task);

  useEffect(() => {
    if (ongoingPagination == "all") {
      fetchtask();
    } else if (ongoingPagination == "jobs-searching") {
      console.log("called handleSearching");
      handleSearching();
    }
    else
    if(ongoingPagination=="datefilter")
    {
      fetchCompleteTasks(true,currentTaskStatus,currentPage);
    }
  }, [currentPage]);

  return (
    <>
      <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
        <div className="w-[100%] mx-auto pb-[65px]">
          <div className="flex mb-[30px] gap-[20px] items-center">
            <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
              All Jobs
            </h1>
          </div>

          <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse   ">
            <div className="flex flex-wrap w-full gap-y-[10px]">
              <div className="flex flex-row mr-[5px] min-h">
                <div className="relative">
                  <select
                    onChange={handleTaskChange}
                    className="px-[20px] h-full rounded-[5px] focus:outline-none focus:none appearance-none min-w-[150px] border border-bordergray"
                  >
                    <option value="all">All</option>
                    <option value="open">Open</option>
                    <option value="completed">Completed</option>
                    <option value="in-progress">In-progress</option>
                    <option value="assigned">Assigned</option>
                    <option value="closed">Closed</option>
                    <option value="not-completed">Not Completed</option>
                  </select>

                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                    <FaChevronDown />
                  </div>
                </div>
              </div>

              <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[45px] min-h border border-bordergray mr-[5px]">
                <img
                  src="/assets/images/search-icon.svg"
                  className="mr-[5px] search--icon"
                />
                <input
                  type="text"
                  placeholder="Search by title"
                  className="input--text max-w-[100%] focus:outline-none focus:none"
                  value={searchKeywords}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              {/* 
              <div className="filter--submit--btn">
                <button className="flex items-center max-w-[109px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[20px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600]">
                  Search
                </button>
              </div>
              */}
            </div>

            <div className="flex gap-[5px] w-full justify-start md:justify-end">
			 
					 <div className="float-left bg-white px-2 rounded-lg border border-bordergray"> 
				
						  <span className="block">Start Date</span>
		<input type="date"    value={startDate || ''} onChange={(e) => {
		setStartDate(e.target.value);
			
		}} /> 
				  </div>
				  <div className="float-left bg-white px-2 rounded-lg border border-bordergray"> 
				
					  <span className="block">End Date </span>
					  <input type="date"  value={endDate || ''} onChange={(e) => {
						  setEndDate(e.target.value);
					     
					  }} /> 
		  </div>
              
            </div>
          </div>
          <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full  md:flex-row flex-col-reverse   ">
		  
		  <button className="flex items-center max-w-[140px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
                Total Jobs : {totalPosts || 0}
              </button>
		  
				  
		  </div>
          <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-lightcolor border-b border-textcolor">
                <tr>
                  <th className="p-4 font-[400]">S.no</th>
                  <th className="p-4 font-[400]">Directors</th>
                  <th className="p-4 font-[400]">Job Title</th>
                  <th className="p-4 font-[400] min-w-[150px]">Budget</th>
                  <th className="p-4 font-[400] min-w-[150px]">Created At</th>

                  {/* <th className="p-4 font-[400]">Hustlers</th> */}
                  <th className="p-4 font-[400] min-w-[150px]">Due Date</th>
                  <th className="p-4 font-[400]">Status</th>

                  <th className="p-2 font-[400] w-[50px]">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Task &&
                  Task.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]">
                        <p className="text-gray-900 whitespace-no-wrap text-textcolor">
                         
                          { currentPage==1? (index  + 1):((currentPage-1)*25)+(index  + 1)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className="w-full h-full rounded-full"
                              src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.user?.profilePic}`}
                            />
                          </div>
                          <div className="ml-3 overflow-hidden">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.user?.fname} {item?.user?.lname}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item?.title}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[100px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          ${BudgetFormat(`${item.finalPrice || item?.budget}`)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {setReportFormatDate(item?.createdAt)}
                        </p>
                      </td>

                      {/*

                      <td className="p-4">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className="w-full h-full rounded-full"
                              src={`${process.env.REACT_APP_BASEURL}/${item.user.profilePic}`}
                            />
                          </div>
                          
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.user?.fname} {item?.user?.lname}
                            </p>
                          </div>
                        </div>
                      </td>
                       */}

                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {setReportFormatDate(item?.dueDate)}
                        </p>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[150px]">
                        <p
                          className={`font-[600] whitespace-no-wrap 
                             ${
                               item?.blocked == true
                                 ? "!text-[#E90000]"
                                 : "text-textcolor"
                             }
                          ${
                            item?.status === "in-progress"
                              ? "!text-[#FFA011]"
                              : "text-textcolor"
                          }
                          ${
                            item?.status === "open"
                              ? "!text-[#0067E0]"
                              : "text-textcolor"
                          }
                          ${
                            item?.status === "pending"
                              ? "!text-[#F7CB73]"
                              : "text-textcolor"
                          }
                          ${
                            item?.status === "closed"
                              ? "!text-[#FF5733]"
                              : "text-textcolor"
                          }
                          ${
                            item?.status === "assigned"
                              ? "!text-[#0096FF]"
                              : "text-textcolor"
                          }
                          ${
                            item?.status === "completed"
                              ? "!text-[#2F965D]"
                              : "text-textcolor"
                          }
                          ${
                            item?.status === "cancel"
                              ? "!text-[#E90000]"
                              : "text-textcolor"
                          }
                        `}
                        >
                          {/* {item?.blocked==true?<> {item?.blocked} </>:<>{item?.status}</>} */}
                          {item?.blocked ? <> blocked </> : <>{item?.status}</>}
                        </p>
                      </td>

                      <td className="p-2 border-b border-gray-200 text-sm max-w-[80px]">
                        <div className="action--btn flex gap-[10px] relative">
                          <div
                            className="more--icon cursor-pointer w-[30px] h-[30px] bg-lightgray rounded-[5px] flex items-center justify-center"
                            onClick={() => handleActiveActionDropdown(item)}
                          >
                            <IoMdMore />
                          </div>
                          {activeActionDropdown === item && (
                            <div className="action--dropdown">
                              <ul>
                                {item.blocked && (
                                  <li onClick={() => UnblockTask(item._id)}>
                                    <span> unblock task</span>
                                  </li>
                                )}
                                <li onClick={() => fetchTaskDetails(item._id)}>
                                  <span> View Details</span>
                                </li>

                                {item.status == "completed" && (
                                  <li onClick={() => handlePayout(item)}>
                                    <span> Payout</span>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {showTaskModel && (
              <ViewTaskModal
                handleMarkTaskIncomplete={handleMarkTaskIncomplete}
                setActiveActionDropdown={setActiveActionDropdown}
                taskDetails={taskDetails}
                setshowTaskModel={setshowTaskModel}
                handleMarkTaskComplete={handleMarkTaskComplete}
              />
            )}

            {showTaskConfirmationModel.model && (
              <TaskConfirmationModel
                showTaskConfirmationModel={showTaskConfirmationModel}
                handleMarkTaskIncomplete={handleMarkTaskIncomplete}
                handleMarkTaskComplete={handleMarkTaskComplete}
                setshowTaskConfirmationModel={setshowTaskConfirmationModel}
              />
            )}
          </div>
          <Pagination
            currentPage={currentPage}
            totalPosts={totalPosts}
            paginate={paginate}
            postsPerPage={postsPerPage}
            length={Task?.length}
          />

          {/* end pagination */}
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(AllTask);
