import React, { useEffect, useState } from "react";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import { IoMdMore } from "react-icons/io";
import Pagination from "../../Common/Pagination";
import { useSocket } from "../../config/SocketContext";
import { authAxios } from "../../config/config";
import { useSelector } from "react-redux";
import { BudgetFormat, setFormatDate, setReportFormatDate } from "../../Helper/helper";
import JobCompletedStatus from "../../Common/JobCompletedStatus";
import JobBookedNotification from "../../Common/JobBookedNotification";
import { toast } from "react-toastify";

const AllNotification = (props) => {
  const { setLoading } = props;
  const [activeNotificationTab, setActiveNotificationTab] = useState("booked");
  const [notification, setNotification] = useState([]);
  const [Messages, setMessages] = useState([]);
  const [searchKeywords, setsearchKeywords] = useState("");
  const user = useSelector((state) => state?.auth?.user);
  const socket = useSocket();

  const fetchNotifications = async () => {
    try {
      const response = await authAxios().get(
        `/notifications/list-notification/${user?.userId}`
      );
      // console.log(response, "advh j hjfdfd")
      if (response?.data && response?.data?.status === 1) {
        console.log(response.data.data);
        //  console.log(response.data.message, "sahjcdsh jhdfj fhdjhf ")
        setMessages(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleSearch = async (value) => {
    if (value) {
      const payload = {
        query: value,
      };
      setsearchKeywords(value);
      await authAxios()
        .get(`/adminsearch/search-notifications`, { params: payload })
        .then((response) => {
          const resData = response?.data;
          if (resData?.status == 1) {
            console.log(resData.data, "Search results");
            
          if (resData.data && resData.data.length > 0) {
            setMessages(resData?.data);
          } else {
            toast.info("No notification found");
          }

          } else {
            toast.error(resData?.error);
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      fetchNotifications();
      setsearchKeywords("");
    }
  };

  useEffect(() => {
    fetchNotifications();

    const handleNewNotification = (notification) => {
      console.log("sdsd", notification);
      setMessages((prevMessages) => [...prevMessages, notification]);
    };

    if (socket) {
      socket.on("newNotification", handleNewNotification);
    }

    return () => {
      if (socket) {
        socket.off("newNotification", handleNewNotification);
      }
    };
  }, [user?.userId, socket]);

  const handleActiveNotificationTab = (tab) => {
    setActiveNotificationTab(tab);
  };

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            All Notifications
          </h1>
        </div>

        <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse ">
       
       {/* <div className="filter--search flex">
            <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[45px] min-h border border-bordergray">
              <img
                src="/assets/images/search-icon.svg"
                className="mr-[5px] search--icon"
              />
              <input
                type="text"
                placeholder="Search notification"
                className="input--text"
                value={searchKeywords}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
           */}  

          <div className="flex gap-[5px] gap-y-[10px] flex-wrap w-full justify-end">
            {/* <button className="flex items-center max-w-[90px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
              Total: {Messages?.length}
            </button> */}
          </div>
        </div>

        <div className="tab--notification flex gap-[5px] sm:flex-row flex-col">
          <button
            type="submit"
            className={`max-w-[150px] flex items-center border w-full justify-center py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] hover:border-buttonbg
            ${
              activeNotificationTab === "booked"
                ? "border-buttonbg bg-buttonbg text-white"
                : "bg-white"
            }
            `}
            onClick={() => handleActiveNotificationTab("booked")}
          >
            Booked
          </button>
          <button
            type="submit"
            className={`max-w-[170px] flex items-center border w-full justify-center py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] hover:border-buttonbg
            ${
              activeNotificationTab === "jobcompleted"
                ? "border-buttonbg bg-buttonbg text-white"
                : "bg-white"
            }
            `}
            onClick={() => handleActiveNotificationTab("jobcompleted")}
          >
            Job Completed
          </button>
        </div>

        <div className="relative pb-0 mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller  area--table">
          {activeNotificationTab && activeNotificationTab === "booked" ? (
            <>
              <h3 className="text-[clamp(18px,2vw,22px)] mb-[10px]">
                Director booked a Hustler
              </h3>
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-lightcolor border-b border-textcolor">
                  <tr>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      {" "}
                      Director Name
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      Hustler Name
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400] min-w-[150px]">
                      Cost
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400] min-w-[200px]">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 font-[400] w-[150px]  {item.taskId.user.fname}"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Messages && Messages?.length > 0 ? (
                    Messages?.map(
                      (item, index) =>
                        item?.taskId?.status === "in-progress" && (
                          <>
                            <tr
                              className="bg-white border-b hover:bg-gray-50"
                              key={index}
                            >
                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {item?.taskId?.user?.fname}{' '}{item?.taskId?.user?.lname}
                              </td>
                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {item?.taskId?.hustlerId?.fname}{' '}{item?.taskId?.hustlerId?.lname}
                              </td>
                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[100px]"
                              >
                                ${BudgetFormat(`${item?.taskId?.budget}`)}
                              </td>
                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {setReportFormatDate(item?.taskId?.createdAt)}
                              </td>

                              <th
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor lg:max-w-[150px] max-w-[250px]"
                              >
                                <p className="max-w-[800px] w-full">
                                  {item?.taskId?.title}
                                </p>
                              </th>

                              <td className="px-3 py-4 max-w-[150px]">
                                <JobBookedNotification
                                  director={item?.taskId?.user}
                                  hustler={item?.taskId?.hustlerId}
                                  taskDetails={item?.taskId}
                                  chatId={item?.chat?._id}
                                />
                              </td>
                            </tr>
                          </>
                        )
                    )
                  ) : (
                    <p>No notification to display.</p>
                  )}
                </tbody>
              </table>
              {/* end table */}
            </>
          ) : null}

          {/* Director booked a hustler */}

          {activeNotificationTab && activeNotificationTab === "jobcompleted" ? (
            <>
              <h3 className="text-[clamp(18px,2vw,22px)] mb-[10px]">
                Job completed status
              </h3>
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-xs text-lightcolor border-b border-textcolor">
                  <tr>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      {" "}
                      Director Name
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      Hustler Name
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      Title
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      City
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      Date Posted
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400]">
                      Finish Date
                    </th>
                    <th scope="col" className="px-3 py-3 font-[400] w-[150px]">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Messages && Messages?.length > 0 ? (
                    Messages?.map(
                      (item, index) =>
                        item?.taskId?.status === "completed" && (
                          <>
                            <tr
                              className="bg-white border-b hover:bg-gray-50"
                              key={index}
                            >
                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {item?.taskId?.user?.fname}
                              </td>

                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {item?.taskId?.hustlerId?.fname}
                              </td>

                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor lg:max-w-[150px] max-w-[250px]"
                              >
                                <p className="w-full">
                                  {item?.taskId?.title}
                                </p>
                              </td>

                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                <p className="text-[#2F965D]">
                                  {item?.taskId?.status}
                                </p>
                              </td>
                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                <p className="text-[#FF833E]">
                                  {item?.taskId?.cityName}
                                </p>
                              </td>
                              {/* <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {setReportFormatDate(item.createdAt)}
                              </td>                             */}

                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {setReportFormatDate(item?.taskId?.createdAt)}
                              </td>
                              <td
                                scope="row"
                                className="px-3 py-4 font-medium text-textcolor max-w-[150px]"
                              >
                                {setReportFormatDate(item?.taskId?.updatedAt)}
                              </td>

                              <td className="px-3 py-4 max-w-[150px]">
                                <JobCompletedStatus
                                  director={item?.taskId?.user}
                                  hustler={item?.taskId?.hustlerId}
                                  taskDetails={item?.taskId}
                                />
                              </td>
                            </tr>
                          </>
                        )
                    )
                  ) : (
                    <p>No notification to display</p>
                  )}
                </tbody>
              </table>
              {/* end table */}
            </>
          ) : null}

          {/* Job completed status */}
        </div>
      </div>
    </div>
  );
};

export default IsLoadingHOC(AllNotification);
