import React, { useEffect, useState } from "react";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import { toast } from "react-toastify";
import { authAxios } from "../../config/config";
import { BudgetFormat, setFormatDate, setReportFormatDate } from "../../Helper/helper";
import { FaEye } from "react-icons/fa";
import ViewTaskDetail from "../../Common/ViewTaskDetail";
import { savePaymentInvoice } from "../../Redux/Reducers/appSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";


const PaymentDetail = (props) => {
  const { setLoading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paymentDetails, setpaymentDetails] = useState([]);
  const [currentTaskStatus, setcurrentTaskStatus] = useState("All");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(25);
  const [payments, setPayments] = useState([]);
  const [totalPosts, settotalPosts] = useState(0);
  const [ongoingPagination, setongoingPagination] = useState("all");

  const [showTaskDetail, setshowTaskDetail] = useState({
    model: false,
    data: [],
  });

  const handleFetchSubscriptionDetail = async (number) => {
    setLoading(true);
    try {
      const response = await authAxios().get(
        `/stripe/list-stripe-payment-details?status=${currentTaskStatus}&page=${
          number || currentPage
        }&limit=${postsPerPage}`
      );
      setLoading(false);
      const resData = response?.data;
      console.log(resData)
      if (resData?.status === 1) {
        setpaymentDetails(resData?.data.payments);
        settotalPosts(resData.data.totalPages);
      } else {
        toast.error(resData?.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleView = (item) => {
    setshowTaskDetail((prev) => ({
      ...prev,
      model: true,
      data: item,
    }));
  };
  const handleTaskChange = (e) => {
    const item = e.target.value;

    
    setcurrentTaskStatus(item);
    setcurrentPage(1);
    setongoingPagination("all");
  };

  useEffect(() => {
    handleFetchSubscriptionDetail();
  }, []);

  useEffect(() => {
    handleFetchSubscriptionDetail();
  }, [currentTaskStatus]);

  const handleNavigateInvoice = (data) => {
    dispatch(savePaymentInvoice(data))
    navigate('/payment-invoice')
  }

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto pb-[65px]">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            Payment Details
          </h1>
        </div>
        <div className="flex mb-[30px] gap-[20px] h-full font-[600] min-h gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse   ">
        <div className="flex flex-wrap w-full gap-y-[10px]">
        <div className="flex flex-row mr-[5px] min-h">
        <h1 className="text-[clamp(10px,2vw,15px)] pt-3 font-semibold">
            Job Status&nbsp;
          </h1>
                <div className="relative">
                  <select
                    onChange={handleTaskChange}
                    className="px-[20px] h-full rounded-[5px] focus:outline-none focus:none  appearance-none min-w-[150px] border border-bordergray"
                  >
                    <option value="all">All</option>
                    <option value="open">Open</option>
                    <option value="completed">Completed</option>
                    <option value="in-progress">In-progress</option>
                    <option value="assigned">Assigned</option>
                    <option value="closed">Closed</option>
                    <option value="not-completed">Not Completed</option>
                  </select>

                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                    <FaChevronDown />
                  </div>
                </div>
              </div>
              </div>
              <div className="flex gap-[5px] w-full justify-start md:justify-end">
		  
		  <button className="flex items-center max-w-[140px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
                Total Jobs : {totalPosts || 0}
              </button>
		  
				  
		  </div>
              </div>
              

        <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-lightcolor border-b border-textcolor">
              <tr>
                <th className="p-4 font-[400]">Subscription ID</th>
                <th className="p-4 font-[400]">Amount</th>
                <th className="p-4 font-[400]">Job Status</th>
                <th className="p-4 font-[400]">View Invoice</th>
                <th className="p-4 font-[400]">Payment Details</th>
                {/*  <th className="p-4 font-[400]">Task Details</th>
                <th className="p-4 font-[400]">Created</th>
                <th className="p-4 font-[400]">View Invoice</th>
                   <th className="p-4 font-[400]">Invoice PDF</th>*/}
              </tr>
            </thead>

            <tbody>
              {paymentDetails &&
                paymentDetails.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {item?._id}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px] min-w-[150px]">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {/* ${item?.payment.amount / 100} */}
                        ${BudgetFormat(`${item?.payment.amount / 100}`)}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]">
                      <p
                        className={`font-[600] whitespace-no-wrap 
                          ${item?.task?.status === "in-progress"
                            ? "!text-[#FFA011]"
                            : "text-textcolor"
                          }
                          ${item?.task?.status === "open"
                            ? "!text-[#0067E0]"
                            : "text-textcolor"
                          }
                          ${item?.task?.status === "pending"
                            ? "!text-[#F7CB73]"
                            : "text-textcolor"
                          }
                          ${item?.task?.status === "closed"
                            ? "!text-[#FF5733]"
                            : "text-textcolor"
                          }
                          ${item?.task?.status === "assigned"
                            ? "!text-[#0096FF]"
                            : "text-textcolor"
                          }
                          ${item?.task?.status === "completed"
                            ? "!text-[#2F965D]"
                            : "text-textcolor"
                          }
                          ${item?.task?.status === "cancel"
                            ? "!text-[#E90000]"
                            : "text-textcolor"
                          }
                        `}
                      >
                        {item?.task?.status}
                      </p>
                    </td>
                    <td
                      onClick={() => handleNavigateInvoice(item)}
                      // onClick={() => window.open(item.stripeInvoiceUrl)}
                      className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]"
                    >
                      <p className="text-gray-900 whitespace-no-wrap cursor-pointer">
                        View Invoice
                      </p>
                    </td>
                    <td
                      onClick={() => handleView(item.task)}
                      className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]  cursor-pointer"
                    >
                      <FaEye />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {showTaskDetail.model && (
          <ViewTaskDetail
            showTaskDetail={showTaskDetail}
            setshowTaskDetail={setshowTaskDetail}
          />
        )}
      </div>
    </div>
  );
};

export default IsLoadingHOC(PaymentDetail);
