import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Select from "react-select";
import Creatable, { useCreatable } from 'react-select/creatable';

const PopupCategory = ({
  setshowPopupCategory,
  CategoriesData,
  CategoryDetails,
  handleAddService,
  setActiveActionDropdown,
  handleUpdateCategory,
  setCategoryDetails,
}) => {
  const [serviceData, setserviceData] = useState({
    id: "",
    serviceName: "",
    serviceDescription: "",
    category: [],
    image: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setserviceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    /*
    const formData = new FormData();
    formData.append("profile_picture", file);
    */

    /*
    setserviceData((prev)=>({
        ...prev,
        image:
    }))*/
  };

  const handleServiceChange = (selectedOptions) => {
    setserviceData((prev) => ({
      ...prev,
      category: selectedOptions || [],
    }));
  };

  const handleAddCategory = (e) => {
    e.preventDefault()
    
    const payload = {
      serviceName: serviceData.serviceName,
     // serviceDescription: serviceData.serviceDescription,
      category: serviceData.category,
     // image: "",
    };
  console.log("payload",payload)
    handleAddService(payload);
    
  };

  const handleUpdate = (e) => {
    e.preventDefault()
    
    setserviceData((prev) => ({
      ...prev,
      id: "",
      serviceName: "",
    //  serviceDescription: "",
      category: [],
      image: "",
    }));
    handleUpdateCategory(serviceData);
    
  };

  const handleclosePopup = () => {
    setserviceData((prev) => ({
      ...prev,
      id: "",
      serviceName: "",
      serviceDescription: "",
      category: [],
      image: "",
    }));
    setActiveActionDropdown("");
    setshowPopupCategory(false);
    setCategoryDetails([]);
  };

  useEffect(() => {
    setserviceData((prev) => ({
      ...prev,
      id: CategoryDetails?._id || "",
      serviceName: CategoryDetails.serviceName || "",
//serviceDescription: CategoryDetails.serviceDescription || "",
      category: CategoryDetails.category || [],
   //   image: "",
    }));
  }, []);

  

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <form onSubmit={serviceData?.id == ""?handleAddCategory:handleUpdate } className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg rounded-[15px]">
            <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4 modal--popupservice">
              <div className="">
                <div className="pophead flex justify-between items-center mb-[10px]">
                  {serviceData.id == "" ? (
                    <h2 className="font-semibold text-[20px]">
                      Add
                    </h2>
                  ) : (
                    <h2 className="font-semibold text-[20px]">
                      Update 
                    </h2>
                  )}

                  <div
                    className="close cursor-pointer text-[20px]"
                    onClick={handleclosePopup}
                  >
                    <IoCloseSharp />
                  </div>
                </div>
                <div className="mb-[10px]">
                  <label className="block text-sm leading-6 text-gray-900">
                    Category Name
                  </label>
                  <div className="mt-2.5">
                    <input
                      required
                      type="text"
                      name="serviceName"
                      onChange={handleChange}
                      value={serviceData.serviceName}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="mb-[10px]">
                  <label className="block text-sm leading-6 text-gray-900">
                    Select Service
                  </label>
                  <div className="mt-2.5">
                    <Creatable
                      required
                      isMulti
                      options={CategoriesData}
                      value={serviceData?.category}
                      onChange={handleServiceChange}
                      isClearable
                    />
                  </div>
                </div>
                
                {/* 
                <div className="mb-[10px]">
                  <label className="block text-sm leading-6 text-gray-900">
                    Upload Image
                  </label>
                  <div className="mt-2.5">
                    <input
                      disabled
                      type="file"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      onChange={handleImage}
                    />
                  </div>
                </div>



                <div className="mb-[10px]">
                  <label className="block text-sm leading-6 text-gray-900">
                    Description
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      required
                      type="text"
                      name="serviceDescription"
                      onChange={handleChange}
                      value={serviceData.serviceDescription}
                      className="block w-full rounded-md border-0 px-3.5 py-2 h-[100px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                </div>
                */}
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-[10px] justify-end rounded-b-[15px]">
              {serviceData?.id == "" ? (
                <button
                  type="submit"
                //  onClick={handleAddCategory}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Submit
                </button>
              ) : (
                <button
                 // onClick={handleUpdate}
                  type="submit"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Update
                </button>
              )}

              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={handleclosePopup}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PopupCategory;
