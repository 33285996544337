import logo from './logo.svg';
import '../src/assets/scss/main.scss'
import Routing from './Routing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

function App() {
  
  return (
      <> 
       <Routing/>
       <ToastContainer/>
       </>
   
  );
}

export default App;
