import React, { useEffect, useState } from "react";
import { IoCheckmark, IoCloseSharp } from "react-icons/io5";
import Select from "react-select";
import { BudgetFormat, setReportFormatDate } from "../../Helper/helper";
import ImageSliderTask from "../../Common/ImageSliderTask";
import { FaCheck } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

const ViewTaskModal = ({
  setshowTaskModel,
  taskDetails,
  handleMarkTaskIncomplete,
  setActiveActionDropdown,
  handleMarkTaskComplete,
}) => {
  const handleClose = () => {
    setshowTaskModel(false);
    setActiveActionDropdown("");
  };

  const handleStatusChange = (e) => {
    const newStatus = taskDetails.status === "pending" ? "open" : "pending";
    console.log(newStatus);
  };

  

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-full overflow-y-auto top-[0] z-3">
        <div className="absolute transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-[95%] max-w-lg top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="bg-white modal--popupservice">
            <div className="">
              <div className="pophead absolute top-[10px] right-[10px] z-[1]">
                <div
                  className="close cursor-pointer text-[25px] bg-white "
                  onClick={handleClose}
                >
                  <IoCloseSharp />
                </div>
              </div>

              <div className="w-full sm:w-full md:w-full xl:w-full">
                <div className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden">
                  <div className={`relative overflow-hidden h-80 ${taskDetails?.attachments.length > 0 ? null : 'blank--image--container'}`}>
                    {/*   <img
                      className="absolute inset-0 h-full w-full object-cover"
                      src="https://images.unsplash.com/photo-1475855581690-80accde3ae2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80"
                      alt=""
                    />
                    {taskDetails.status === 'pending' ? (
                      <>
                        <button
                          className="z-[1] relative bg-[#009d0d] text-white max-w-[150px] w-full rounded-[50px] flex items-center justify-center p-[10px] mt-[10px] ml-[10px] cursor-pointer"
                          onClick={handleStatusChange}
                        >
                          Active
                        </button>
                      </>
                    ) : null}
                    */}

                    {taskDetails?.attachments.length > 0 ? (
                      <div className="block h-full">
                        <ImageSliderTask
                          images={taskDetails.attachments.map(
                            (item) => `${process.env.REACT_APP_IMAGE_URL}job_attachments/${item}`
                          )}
                        />
                      </div>
                    ) : (
                      <>
                      <div className="h-full p-3 flex flex-col items-center justify-center gap-1 bg-[#f1f1f1]">
                        <img src="../assets/images/no-image.jpg" className="w-full max-w-[300px] mx-[auto]" />
                        <h2 className="text-center italic font-normal">No Image available</h2>
                      </div> 
                      </>
                    )}
                  </div>




                  {taskDetails?.status=="open"?"":
                  
                  <h2 className="mt-2 ml-4 mb-2  font-bold">
                  Hustler : {taskDetails?.hustlerId?.fname + ' ' + taskDetails?.hustlerId?.lname}
                  </h2>
}
                  
                  <h2 className="mt-2 ml-4 mb-2  font-bold">
                  Job Id : {taskDetails?._id}
                  </h2>

                  <div className="p-4">
                    <span className="inline-block px-2 py-1 leading-none bg-orange-200 text-orange-800 rounded-full font-semibold uppercase tracking-wide text-xs">
                      {taskDetails?.serviceName}
                    </span>

                    <h2 className="mt-2 mb-2  font-bold">
                      {taskDetails?.title}
                    </h2>
                    <p className="text-sm mt-5">
                      {taskDetails?.description
                        ? taskDetails?.description.substring(0, 400) + "..."
                        : "No description available"}
                    </p>

                    <div className="flex flex-wrap mt-5 gap-[5px]">
                      <div className="mb-2">
                        <span className="text-md  flex items-center  border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px]  rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h">
                          ${BudgetFormat(`${taskDetails?.budget}`)}
                        </span>
                      </div>

                      <div className="mb-2">
                        <span className="text-md  flex items-center  border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px]  rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h">
                          {setReportFormatDate(taskDetails?.dueDate)}
                        </span>
                      </div>

                      <div className="mb-2 ">
                        <span className="text-md  flex items-center  border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px]  rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h">
                          {taskDetails?.cityName}
                        </span>
                      </div>

                      {/* <div className="mb-2">
                        <span className="text-md font-medium capitalize flex items-center sm:max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px]  rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h">
                          {taskDetails?.status}
                        </span>
                      </div> */}
                    </div>
                  </div>
                  {taskDetails.status == "not-completed" && (
                    <>
                      {taskDetails?.taskCompletionId?.reasonForNotCompleted && (
                        <div className="block p-4 h-full pt-0">
                          <span className="text-center">
                            <b>Reason:</b>{" "}
                            {
                              taskDetails?.taskCompletionId
                                ?.reasonForNotCompleted
                            }
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  {taskDetails.status == "not-completed" && (
                    <>
                      {taskDetails?.taskCompletionId?.reasonForNotCompleted && (
                        <div className="p-4 pt-0 relative flex items-center gap-3 justify-between mt-[10px]">
                          <button
                            onClick={() =>
                              handleMarkTaskComplete()
                            }
                            className="flex items-center max-w-[200px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h gap-[5px] cursor-pointer"
                          >
                            <IoCheckmark className="text-[18px]" /> Mark
                            complete
                          </button>

                          <button
                            onClick={() =>
                              handleMarkTaskIncomplete()
                            }
                            className="flex items-center max-w-[200px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h gap-[5px] cursor-pointer"
                          >
                            <IoIosClose className="text-[22px]" /> Mark
                            Incomplete{" "}
                          </button>
                        </div>
                      )}
                    </>
                  )}

                  {/* <div className="p-4 border-t border-b text-xs text-gray-700">
                    <span className="flex items-center gap-[8px]">
                      {taskDetails.serviceCategories &&
                        taskDetails.serviceCategories.map((item) => (
                          <>
                            <div>
                              <div
                                className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-gray-900 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                                type="button"
                              >
                                {item}
                              </div>
                            </div>
                          </>
                        ))}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTaskModal;
