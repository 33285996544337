import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { authAxios } from '../config/config'

const ViewTask = () => {
    const param =useParams()
    const {id}=param

    const fetchTaskDetails=async()=>{
        await authAxios()
        .get(`/task/get-specific-task/${id}`)
        .then((response)=>{
            console.log(response)
        }).catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchTaskDetails()
    },[])
    
   
  return (
    <>
    
    </>
  )
}

export default ViewTask