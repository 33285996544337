import React, { useEffect, useState } from "react";
import { authAxios } from "../../config/config";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../Common/IsLoadingHOC";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import PopupService from "./PopupService";
import { IoIosMore, IoMdMore } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import DeleteModal from "./DeleteModal";
import { setReportFormatDate } from "../../Helper/helper";
import Pagination from "../../Common/Pagination";

const Services = (props) => {
  const { setLoading } = props;
  const [activeActionDropdown, setActiveActionDropdown] = useState("");
  const [showDeletemodal, setshowDeletemodal] = useState(false);
  const [categoryId, setcategoryId] = useState("");
  const [showAddCategory, setshowAddCategory] = useState(false);
  const [searchKeywords, setsearchKeywords] = useState("");
  const [CategoryDetails, setCategoryDetails] = useState({
    id: "",

    value: "",
  });
  const [Categories, setCategories] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(5);
  const [totalPosts, settotalPosts] = useState(0);
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const handleActiveActionDropdown = (item) => {
    setActiveActionDropdown((prevItem) => (prevItem === item ? null : item));
  };

  const DeleteTask = async (id) => {
    setshowDeletemodal(true);
    setcategoryId(id);

    if (showDeletemodal === true) {
      setLoading(true)
      await authAxios()
        .delete(`/services/deleteCategory/${categoryId}`)
        .then((response) => {
          setLoading(false);
          if (response?.data?.status == 1) {
          
          toast.success("Service deleted successfully") 
           setshowDeletemodal(false);
            setActiveActionDropdown("");
            setActiveActionDropdown("");
            fetchCategory();
          } else {
            toast.error("");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const editCategory = async (item) => {
    setCategoryDetails((prev) => ({
      ...prev,
      id: item?._id,
      value: item?.serviceName,
    }));

    setshowAddCategory(true);

    if (showAddCategory === true) {
      const payload = {
        label: item?.value,
        value: item?.value,
      };
     setLoading(true)
      await authAxios()
        .put(`/services/editCategory/${item?.id}`, payload)
        .then((response) => {
          const resData = response?.data;
          setLoading(false);
          if (resData?.status == 1) {
            toast.success("Service Updated Sucessfully")
            setshowAddCategory(false);
            setCategoryDetails({
              id: "",

              value: "",
            });

            fetchCategory();
          } else {
            toast.error("");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleaddCategory = async (item) => {
    const payload = {
      label: item,
      value: item,
    };

    await authAxios()
      .post("/services/add-Category", payload)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData?.status == 1) {
          toast.success("Service Added Sucessfully") 
         // toast.success(resData.message);
          setshowAddCategory(false);
          fetchCategory();
        } else {
          //toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const fetchCategory = async () => {
    setLoading(true)
    await authAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        setLoading(false)
        const resData = response?.data;
        if (resData?.status == 1) {
          console.log(response)
          console.log(resData.data)
          setCategories(resData?.data);
        } else {
          toast.error(resData?.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSearchService = async (item) => {
    if (item) {
      const payload = {
        query: item,
      };
      setsearchKeywords(item);
      setLoading(true)
      await authAxios()
        .get("/services/search-categories", { params: payload })
        .then((response) => {
          setLoading(false)
          const resData = response?.data;
          if (resData?.status === 1) {
            setCategories(resData?.data);
            
          } else {
            toast.error(resData?.message);
            
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error(error?.response?.data?.message);
          
        });
    } else {
      fetchCategory();
      setsearchKeywords("");
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
      <div className="w-[100%] mx-auto pb-[65px]">
        <div className="flex mb-[30px] gap-[20px] items-center">
          <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
            Services
          </h1>
        </div>

        <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse ">
          <div className="filter--search flex">
            <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[45px] min-h border border-bordergray">
              <img src="/assets/images/search-icon.svg" className="mr-[5px] search--icon" />
              <input
                type="text"
                placeholder="Search here"
                value={searchKeywords}
                className="input--text"
               // onChange={(e) => handleSearchService(e.target.value)}
              />
            </div>


           


          </div>

          <div className="flex gap-[5px] gap-y-[10px] flex-wrap w-full justify-end">
            <button
              className="flex items-center max-w-[90px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h"
            >
              Total: {Categories?.length||0}
            </button>

            <button
            //  onClick={() => setshowAddCategory(true)}
              className="flex items-center max-w-[160px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] min-h"
            >
              <FiPlus />
              Add Services
            </button>
          </div>
        </div>

        <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-lightcolor border-b border-textcolor">
              <tr>
                {/* 
                <th scope="col" className="p-4 font-[400]">
                  <div className="flex items-center custom--checkbox">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <span className="checkmark--custom"></span>
                  </div>
                </th>
                */}

                <th scope="col" className="p-4 w-[50px] font-[400]">
                  <div className="flex items-center">
                    <p className="w-4 h-4">
                      S.No.
                    </p>

                    <label
                      htmlFor="checkbox-all-search"
                      className="sr-only"
                    ></label>
                  </div>
                </th>

                <th scope="col" className="px-6 py-3 w-[350px] font-[400]">
                  {" "}
                  Service Name
                </th>
                <th scope="col" className="px-6 py-3 font-[400]">
                  Created at
                </th>
                <th scope="col" className="px-3 py-3 w-[50px] font-[400]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Categories &&
                Categories.length > 0 &&
                Categories.map((item, index) => (
                  <>
                    <tr className="bg-white border-b hover:bg-gray-50">
                    {/* 
                      <td className="w-4 p-4">
                        <div className="flex items-center custom--checkbox">
                          <input
                            id="checkbox-table-search-1"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <span className="checkmark--custom"></span>
                        </div>
                      </td>
                      */}

                      <td
                        scope="row"
                        className="px-6 py-4 font-medium text-textcolor min-w-[50px]"
                      >
                        {index + 1}
                      </td>

                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-textcolor min-w-[350px]"
                      >
                        {item.serviceName}
                      </th>

                      <td className="px-6 py-4 text-textcolor min-w-[350px]">{setReportFormatDate(item.createdAt)}</td>

                      <td className="px-6 py-4 min-w-[50px]">
                        <div className="action--btn flex gap-[10px] relative">
                          <div
                            className="more--icon cursor-pointer w-[30px] h-[30px] bg-lightgray rounded-[5px] flex items-center justify-center"
                            onClick={() => handleActiveActionDropdown(item)}
                          >
                            <IoMdMore />
                          </div>
                          {/*activeActionDropdown === item && (
                            <div className="action--dropdown z-[1]">
                              <ul>
                                <li onClick={() => editCategory(item)}>
                                  <FaRegEdit /> <span>Edit</span>
                                </li>
                                <li onClick={() => DeleteTask(item._id)} className="!text-red-500">
                                  <RiDeleteBinLine /> <span>Delete</span>
                                </li>
                              </ul>
                            </div>
                          )*/}
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>


        </div>
          {/* end table 
            <Pagination
              currentPage={currentPage}
              totalPosts={totalPosts}
              paginate={paginate}
              postsPerPage={postsPerPage}
            />
          */}
          {/* end pagination */}

        {showAddCategory && (
          <PopupService
            setActiveActionDropdown={setActiveActionDropdown}
            setshowAddCategory={setshowAddCategory}
            handleaddCategory={handleaddCategory}
            CategoryDetails={CategoryDetails}
            setCategoryDetails={setCategoryDetails}
            editCategory={editCategory}
          />
        )}

        {showDeletemodal && (
          <DeleteModal
            setActiveActionDropdown={setActiveActionDropdown}
            setshowDeletemodal={setshowDeletemodal}
            DeleteTask={DeleteTask}
          />
        )}
      </div>
    </div>
  );
};

export default IsLoadingHOC(Services);
