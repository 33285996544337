import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { BsChatLeftDots } from "react-icons/bs";
import { authAxios } from "../config/config";
import NotificationChatModal from "./NotificationChatModal";
import { Link } from "react-router-dom";
import { BudgetFormat } from "../Helper/helper";

const JobBookedNotification = ({ director, hustler, taskDetails, chatId }) => {
  const [jobBookedNotificationModal, setJobBookedNotificationModal] =
    useState(false);
  const [showChatModel, setshowChatModel] = useState(false);
  const [usersChatDetails, setUsersChatDetails] = useState(null);
  console.log("taskDetails", chatId);

  const handleOpenChat = async () => {
    try {
      if (chatId) {
        const response = await authAxios().get(
          `/chats/get-chats-message/${chatId}`
        );
        if (response.data.status === 1) {
          setUsersChatDetails(response?.data?.data);
          setshowChatModel(true);
        } else {
          setUsersChatDetails(null);
        }
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  return (
    <>
      <button
        type="submit"
        className="max-w-[150px] flex items-center border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[15px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600] ml-auto"
        onClick={() => setJobBookedNotificationModal(true)}
      >
        View Details
      </button>

      {jobBookedNotificationModal ? (
        <>
          <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-[95%] sm:max-w-[750px]">
                  <div className="bg-white px-4 pb-4 pt-[15px] sm:p-6 sm:pb-4 modal--popupservice">
                    <div className="">
                      <div className="pophead mb-[10px]">
                        <div className="flex justify-end w-full">
                          <IoCloseSharp
                            onClick={() => setJobBookedNotificationModal(false)}
                            className="cursor-pointer text-[20px]"
                          />
                        </div>

                        <div className="task--complete--info flex sm:flex-row flex-col gap-[30px] sm:gap-[15px]">
                          <div className="w-full director flex gap-[15px] h-[clamp(60px,6vw,103px)]">
                            <div className="img--profile">
                              <Link to={`/profile/${director._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${director?.profilePic}`}
                                  alt="profile--image"
                                  className="rounded-[5px] w-[clamp(60px,6vw,103px)] aspect-square object-cover"
                                />
                              </Link>
                            </div>
                            <div className="profile--info flex flex-col justify-between gap-[10px]">
                              <div>
                                <h2 className="text-[18px] text-black">
                                  {" "}
                                  {director?.fname} {director?.lname}
                                </h2>
                                <small className="text-[14px] text-black">
                                  <i>Director</i>
                                </small>
                              </div>

                              {/* <div>
                                <p className="text-textcolor flex flex-col">
                                  <span className="text-[#909090] font-[400] text-[12px]">
                                    Booked On
                                  </span>{" "}
                                  12 Feb 2024
                                </p>
                              </div> */}
                            </div>
                          </div>
                          {/* end director */}

                          <div className="w-full director flex gap-[15px] h-[clamp(60px,6vw,103px)]">
                            <div className="img--profile">
                              <Link to={`/profile/${hustler?._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${hustler?.profilePic}`}
                                  alt="profile--image"
                                  className="rounded-[5px] w-[clamp(60px,6vw,103px)] aspect-square object-cover"
                                />
                              </Link>
                            </div>
                            <div className="profile--info flex flex-col justify-between gap-[10px]">
                              <div>
                                <h2 className="text-[18px] text-black">
                                  {" "}
                                  {hustler?.fname} {hustler?.lname}
                                </h2>
                                <small className="text-[14px] text-black">
                                  <i>Hustler</i>
                                </small>
                              </div>

                              {/* <div>
                                <p className="text-textcolor flex flex-col">
                                  <span className="text-[#909090] font-[400] text-[12px]">
                                    Accepted On
                                  </span>{" "}
                                  12 Feb 2024
                                </p>
                              </div> */}
                            </div>
                          </div>
                          {/* end hustler */}
                        </div>

                        

                        <div className="mt-[20px]">
                          <p className="text-[14px] text-textcolor mb-[10px] font-[500]">
                            Job Details
                          </p>

                          <div className="bg-[#E9E9E9] rounded-[10px] py-[20px] px-[15px] h-[190px] overflow-y-auto over--scroller">
                            <p className="text-[14px] mb-[10px]">
                              Cost{" "}
                              <span className="text-textcolor font-[500]">
                                ${BudgetFormat(`${taskDetails?.budget}`)}
                              </span>
                            </p>
                            <h3 className="font-[600] text-[14px] mb-[10px] text-textcolor">
                              {taskDetails?.title}
                            </h3>

                            <p className="font-[400] text-[14px] mb-[10px] text-textcolor">
                              {taskDetails?.description}
                            </p>
                          </div>

                          <div className="category--list">
                            <ul className="flex flex-wrap gap-[5px] mt-[10px]">
                              {taskDetails?.serviceCategories?.map(
                                (item, index) => (
                                  <>
                                    <li
                                      className="bg-black text-white rounded-[50px] px-[15px] py-[5px] text-[12px] font-[500] max-w-[100%]"
                                      key={index}
                                    >
                                      {item}
                                    </li>
                                  </>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showChatModel && usersChatDetails && (
        <NotificationChatModal
          usersChatDetails={usersChatDetails}
          setshowChatModel={setshowChatModel}
        />
      )}
    </>
  );
};

export default JobBookedNotification;
