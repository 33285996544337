import React, { useEffect, useState } from 'react';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";


function CarouselSlider({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    console.log(images, 'images')
  })

  return (
    <div className="carousel-slider h-full relative">
      {images.length > 1 &&

        <div className='pagination--slide absolute top-[50%] w-full flex items-center justify-between px-[15px]'>
          <button className='bg-black text-white w-[40px] aspect-square flex items-center justify-center rounded-[50px]' onClick={prevSlide}><FaAngleLeft /></button>
          <button className="bg-black text-white w-[40px] aspect-square flex items-center justify-center rounded-[50px]" onClick={nextSlide}><FaAngleRight /></button>
        </div>
        
      }
      <div className="image-container h-full">
        <img src={images[currentImageIndex]} className="h-full w-full object-contain object-center" alt={`Slide ${currentImageIndex}`} />
      </div>
    </div>
  );
}

export default CarouselSlider;
