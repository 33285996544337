// searchSlice.js
import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'search',
  initialState: {
    tasks: [],
    paymentInvoice: {},
    subscriptionInvoice: {}
  },
  reducers: {
    saveTaskOptions: (state, action) => {
      state.tasks = action.payload;
    },
    savePaymentInvoice: (state, action) => {
      state.paymentInvoice = action.payload;
    },
    saveSubscriptionInvoice: (state, action) => {
      state.subscriptionInvoice = action.payload;
    },
  },
});

export const { saveTaskOptions, savePaymentInvoice , saveSubscriptionInvoice } = appSlice.actions;

export default appSlice.reducer;
