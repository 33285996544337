import React, { useEffect, useState } from "react";
import { authAxios } from "../config/config";
import { toast } from "react-toastify";
import ChatModal from "./ChatModal";
import { IoIosEye } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaChevronDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import IsLoadingHOC from "./IsLoadingHOC";
import Pagination from "./Pagination";
import { BudgetFormat } from "../Helper/helper";
import { FaRegEdit } from "react-icons/fa";

const Chat = (props) => {
  const { setLoading } = props;
  const [AllchatDetails, setAllchatDetails] = useState([]);
  const [searchKeywords, setsearchKeywords] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(25);
  const [totalPosts, settotalPosts] = useState(0);
  const [showChatModel, setshowChatModel] = useState(false);
  const [usersChatDetails, setusersChatDetails] = useState([]);
  const [ongoingPagination, setongoingPagination] = useState("all");
  const [chatStatus,setChatStatus]=useState("")

  console.log(AllchatDetails,"AllchatDetails")
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const [showModal, setShowModal] = useState(false);
  
  
  const getAllChat = async () => {
    setLoading(true);
    await authAxios()
      .get(
        `/chats/get-all-chat-details?page=${currentPage}&limit=${postsPerPage}&status=${chatStatus}&title=${searchKeywords}`
      )
      .then((response) => {
        console.log(response, "chat response...........");
        const resData = response?.data;
        setLoading(false);
        if (resData?.status == 1) {
          setAllchatDetails(resData?.data);
          settotalPosts(resData?.count?.total);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleChatStatus = async(e) =>
    {
    const chat=e.target.value;
    
    setChatStatus(chat);
    setcurrentPage(1)
   
   
    }
 
  const handleChatDetails = (item) => {
    setusersChatDetails(item);
    setshowChatModel(true);
  };

  const handleSearch = async (value) => {
    if (value) {
      // handleSearching(1, value);
      // getAllChat();
      setcurrentPage(1);
      setongoingPagination("search-chat");
      setsearchKeywords(value)
    } else {
      getAllChat();
      setsearchKeywords("");
      setongoingPagination("search-chat");
    }
  };

  const handleSearching = async (number, value) => {
    const payload = {
      searchTerm: value || searchKeywords,
    };

    console.log("payload tttttttttttt", payload)

    await authAxios()
      .get(
        `/adminsearch/search-chat?page=${number || currentPage
        }&limit=${postsPerPage}`,
        {
          params: payload,
        }
      )
      .then((response) => {
        const resData = response?.data;
        console.log(resData,"reschat");

        if (resData?.status == 1) {
          settotalPosts(resData?.count?.total);
          setAllchatDetails(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  
  
  
  
  //var unblock_id ;
  const [unblockId, setUnblockId] = useState(null);
  const [unblockIdupdate, setUnblockIdupdate] = useState(null);
  
  const unBlockStat = (id)=> {
	//console.log("gggggggg", id) ;
	//unblock_id = id;
	 setUnblockId(id);
      setShowModal(true);	
  }
  
  const updatedChatDetails = async (id) => {
  try {
    // setLoading(true);
   // console.log(id, "testing")
    const response = await authAxios().put(`/chats/chat-block-unblock`, {
      "taskId": id
    });
    //console.log("ttttttttttttt", id) ;    
    const resData = response;
    //console.log(resData,"updatedChatDetails")
    getAllChat();
    // setLoading(false);
   } catch (error) {
    // setLoading(false);
    toast.error(error?.response?.data?.message || "An error occurred");
  }
};

const shownewModal =()=> {
	setShowModal(false)
}

  useEffect(() => {
    if (ongoingPagination == "all") {
      getAllChat();
    } else if (ongoingPagination == "search-chat") {

      getAllChat();
    }
  }, [currentPage,searchKeywords]);
  useEffect(() => {
  getAllChat();
 
  }, []);

  useEffect(()=> {
    getAllChat();

  },[chatStatus]);
  return (
    <>
      <div className="px-[50px] py-[20px] bg-lightgray area--page pb-0">
        <div className="w-[100%] mx-auto pb-[65px]">
          <div className="flex mb-[30px] gap-[20px] items-center">
            <h1 className="text-[clamp(20px,2vw,35px)] leading-none font-semibold">
              All Chats
            </h1>
          </div>

          <div className="flex mb-[30px] gap-[20px] gap-y-[10px] md:items-center w-full md:justify-between md:flex-row flex-col-reverse ">
            <div className="flex flex-wrap w-full gap-y-[10px]">
              <div className="filter--input--search flex items-center bg-white pl-[15px] rounded-[5px] h-[45px] min-h border border-bordergray mr-[5px]">
                <img
                  src="/assets/images/search-icon.svg"
                  className="mr-[5px] search--icon"
                />
                <input
                  type="text"
                  placeholder="Search by director name"
                  className="input--text max-w-[80%] focus:outline-none focus:none"
                  value={searchKeywords}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              { <div className="relative">
		  <select className="px-[20px] border border-white hover:bg-transparent hover:border-textcolor hover:border-textcolor bg-white h-full rounded-[5px] focus:outline-none focus:none appearance-none min-w-[150px]"
               onChange={handleChatStatus} >
                  <option value="">All</option>
                    <option value="open">Open</option>
                    <option value="completed">Completed</option>
                    <option value="in-progress">In-progress</option>
                    <option value="assigned">Assigned</option>
                    <option value="closed">Closed</option>
                    <option value="not-completed">Not Completed</option>
                   
                  
				 
                </select>
			<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                  <FaChevronDown />
                </div>	
		  </div>}
              {/* 
              <div className="filter--submit--btn">
                <button className="flex items-center max-w-[109px] border border-buttonbg w-full justify-center bg-buttonbg text-white py-[8px] px-[20px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:text-textcolor h-[45px] font-[600]">
                  Search
                </button>
              </div>
              */}
            </div>

            <div className="flex gap-[5px] w-full justify-start md:justify-end">
              <button className="flex items-center max-w-[140px] border border-white w-full justify-center bg-white text-textcolor py-[8px] px-[10px] h-[39px] rounded-[5px] duration-[0.3s] hover:bg-transparent hover:border-textcolor hover:text-textcolor h-[45px] font-[600] min-h">
                Total Chats: {totalPosts||0}
              </button>
            </div>
          </div>

          <div className="relative mt-[10px] overflow-x-auto px-[20px] py-[15px] bg-white rounded-t-[10px] h-[100vh] over--scroller area--table">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-lightcolor border-b border-textcolor">
                <tr>
                  <th className="p-4 font-[400]">S.no</th>
                  <th className="p-4 font-[400]">Director</th>
                  <th className="p-4 font-[400]">Job Title</th>
                  <th className="p-4 font-[400]">Hustler</th>
                  <th className="p-4 font-[400] min-w-[150px]">Budget</th>
                  <th className="p-4 font-[400]">Status</th>
                  <th className="p-4 font-[400]">Chats</th>
                </tr>
              </thead>
              <tbody>
                {AllchatDetails && AllchatDetails.length > 0 ?
                  AllchatDetails.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]">
                        <p className="text-gray-900 whitespace-no-wrap text-textcolor">
                          {currentPage==1?(index + 1): ((currentPage-1)*25)+ (index + 1)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <Link to={`/profile/${item?.participants[0]?._id}`}>
                              <img
                                className="w-full h-full rounded-full"
                                src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.participants[0]?.profilePic}`}
                              />
                            </Link>
                          </div>
                          <div className="ml-3 overflow-hidden">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.participants[0]?.fname}{" "}
                              {item?.participants[0]?.lname}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item?.taskDetails?.title}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[200px]">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 w-10 h-10">
                            <Link to={`/profile/${item?.participants[1]?._id}`}>
                              <img
                                className="w-full h-full rounded-full"
                                src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${item?.participants[1]?.profilePic}`}
                                height={80}
                              />
                            </Link>
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {item?.participants[1]?.fname}{" "}
                              {item?.participants[1]?.lname}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[100px]">
                        <p className="text-gray-900 whitespace-no-wrap">
                          ${BudgetFormat(`${item?.taskDetails?.budget}`)}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[120px]">
                      <p
                          className={`font-[600] whitespace-no-wrap 
                             ${
                               item?.taskDetails?.blocked == true
                                 ? "!text-[#E90000]"
                                 : "text-textcolor"
                             }
                          ${
                            item?.taskDetails?.status === "in-progress"
                              ? "!text-[#FFA011]"
                              : "text-textcolor"
                          }
                          ${
                            item?.taskDetails?.status === "open"
                              ? "!text-[#0067E0]"
                              : "text-textcolor"
                          }
                          ${
                            item?.taskDetails?.status === "pending"
                              ? "!text-[#F7CB73]"
                              : "text-textcolor"
                          }
                          ${
                            item?.taskDetails?.status === "closed"
                              ? "!text-[#FF5733]"
                              : "text-textcolor"
                          }
                          ${
                            item?.taskDetails?.status === "assigned"
                              ? "!text-[#0096FF]"
                              : "text-textcolor"
                          }
                          ${
                            item?.taskDetails?.status === "completed"
                              ? "!text-[#2F965D]"
                              : "text-textcolor"
                          }
                          ${
                            item?.taskDetails?.status === "cancel"
                              ? "!text-[#E90000]"
                              : "text-textcolor"
                          }
                        `}
                        >
                          {item?.blocked==true?<> {item?.blocked} </>:<>{item?.taskDetails?.status}</>}
                          {/* {item?.taskDetails?.blocked ? <> blocked <FaRegEdit  onClick={() => {
						  unBlockStat(item?.taskDetails?._id);}}/></> : <>{item?.taskDetails?.status}</>}  */}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 text-sm max-w-[80px]">
                        <IoIosEye
                          onClick={() => handleChatDetails(item)}
                          className="text-black text-3xl cursor-pointer"
                        />
                      </td>
                    </tr>
                  ))
                : <tr>
                <td
                  colSpan="6"
                  className="px-6 py-4 text-center text-gray-500"
                >
                  No Data Found
                </td>
              </tr>
              }
                 
              </tbody>
            </table>

          </div>

          <Pagination
            currentPage={currentPage}
            totalPosts={totalPosts}
            paginate={paginate}
            postsPerPage={postsPerPage}
            length={AllchatDetails?.length}
          />
          
        </div>

        {showChatModel && (
          <ChatModal
            usersChatDetails={usersChatDetails}
            setshowChatModel={setshowChatModel}
          />
        )}


    
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-full my-6 mx-auto max-w-[30rem]">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    Change chat status
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <p className="my-4 mt-0 text-blueGray-500 text-lg leading-relaxed">
                  Do you want to unblock this user for chat? 
                  {/*AllchatDetails &&
                      AllchatDetails.map((item, index) => (
                        <div key={index}>
						  <div className="mx-3 w-[25px] float-left">{index + 1}</div>
                          <div className="mx-3 w-[80%] float-left">{item?.taskDetails?._id} </div>
                        </div>
                      ))
                    */}
					
				  {/*unblockId*/}
					
					
                  </p>
                </div>

                <div className="flex items-center justify-center p-3 border-t border-solid border-blueGray-200 rounded-b">             
                <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    No
                  </button>
                  <button  
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                       updatedChatDetails(unblockId);
					   shownewModal();
					
					}}
                  >
				  
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
 



      </div>
    </>
  );
};

export default IsLoadingHOC(Chat);
