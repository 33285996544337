import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import RecentJobs from "./RecentJobs";

const options = {
  chart: {
    type: "donut",
  },
  colors: [ "#375E83", "#259AE6", "#FFA70B"],
  labels: [ "Completed", "Inprogress", "Open"],
  legend: {
    show: true,
    position: "bottom",
  },
  plotOptions: {
    pie: {
      donut: {
        size: "65%",
        background: "transparent",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 380,
        },
      },
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200,
        },
      },
    },
  ],
};

const ChartThree = ({circleDetails}) => {
  
  const [series, setSeries] = useState([]);
  useEffect(()=>{
   setSeries(circleDetails) 
  },[])

  return ( 
    <div className="col-span-12 rounded-[10px] border border-stroke bg-white p-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-6">
      <div className="mb-3 justify-between gap-4 sm:flex">
        <div>
          <h5 className="text-xl font-semibold text-black dark:text-black">
            Job  Analytics
          </h5>
        </div>
        <div>
         
        </div>
      </div>

      <div className="mb-2">
        <div id="chartThree" className="mx-auto flex justify-center">
          <ReactApexChart options={options} series={circleDetails} type="donut" />
        </div>
      </div>

      <div className="-mx-8 flex flex-wrap items-center justify-left gap-y-3 mb-[20px]">
        {/* <div className="w-full px-8 sm:w-1/2">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#10B981]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-black">
              <span> Total Jobs </span>
              <span> {circleDetails[0]} </span>
            </p>
          </div>
        </div> */}

        <div className="w-full px-8 sm:w-1/2">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#375E83]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-black">
              <span> Completed </span>
              <span> {circleDetails[0]} </span>
            </p>
          </div>
        </div>
        <div className="w-full px-8 sm:w-1/2">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#259AE6]"></span>
            <p className="flex w-full justify-between text-sm font-medium text-black dark:text-black">
              <span> Inprogress </span>
              <span> {circleDetails[1]}</span>
            </p>
          </div>
        </div>
        <div className="w-full px-8 sm:w-1/2">
          <div className="flex w-full items-center">
            <span className="mr-2 block h-3 w-full max-w-3 rounded-full bg-[#FFA70B]"></span>
            <p className="flex w-full  justify-between text-sm font-medium text-black dark:text-black">
              <span> Open </span>
              <span> {circleDetails[2]}</span>
            </p>
          </div>
        </div>
      </div>
      <RecentJobs></RecentJobs>
    </div>
  );
};

export default ChartThree;
