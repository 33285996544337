import React, { useEffect, useRef, useState } from "react";
import { FaRegBell, FaRegUser, FaSquare } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../Redux/Reducers/authSlice";
import { Link } from "react-router-dom";
import { useSocket } from "../config/SocketContext";
import { authAxios } from "../config/config";

const TopBar = () => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [shownotifications, setshownotifications] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Messages, setMessages] = useState([]);
  const { fname, lname } = useSelector((state) => state.auth.user);
  const user = useSelector((state) => state?.auth?.user);
  const divRef = useRef(null);

  const socket = useSocket();

  const fetchNotifications = async () => {
    try {
      const response = await authAxios().get(
        `/notifications/list-notification/${user?.userId}`
      );
      // console.log(response, "advh j hjfd fd")
      if (response?.data && response?.data?.status === 1) {
        //  console.log(response.data.message, "sahjcdsh jhdfj fhdjhf ")
        setMessages(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();

    const handleNewNotification = (notification) => {
      console.log("sdsd", notification);
      setMessages((prevMessages) => [...prevMessages, notification]);
    };

    if (socket) {
      socket.on("newNotification", handleNewNotification);
    }

    return () => {
      if (socket) {
        socket.off("newNotification", handleNewNotification);
      }
    };
  }, [user?.userId, socket]);

  const handleNotificationClick = (notificationId) => {
    console.log("Notification clicked:", notificationId);
    navigate("/all-notification");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleActiveSubMenu = (item) => {
    setActiveSubMenu((prevItem) => (prevItem === item ? null : item));
    setshownotifications(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setshownotifications(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="topbar--admin fixed top-[0] left-[0] w-full">
        <Link to="/" className="logo--topbar">
          <img src="/assets/images/white-logo.svg" alt="logo" width={150} />
        </Link>
        <div className="topbar--rightside">
          <ul>
            <li
              className="menu--item bell--notification"
              onClick={(e) => {
                setshownotifications(!shownotifications);
                setActiveSubMenu(false);
              }}
              ref={divRef}
            >
              {/* */}

              {Messages && Messages.length > 0 ? (
                <>
                  <div className="bell--circle"></div>
                  <FaRegBell />
                </>
              ) : (
                <FaRegBell />
              )}
            </li>

            <li
              className={`menu--item ${
                activeSubMenu === "profile" ? "active--menu" : "active--menu"
              }`}
              onClick={() => handleActiveSubMenu("profile")}
            >
              <FaRegUser />
              {activeSubMenu === "profile" && (
                <div className="profile--dropdown">
                  <ul>
                    <li onClick={handleLogout}>Logout</li>
                  </ul>
                </div>
              )}
            </li>
            {/* <div className="profile--info">
              <small>Administator</small>
              <br />
              {fname + " " + lname}
            </div> */}
          </ul>
        </div>
      </div>

      {shownotifications && Messages && (
        <div className="fixed top-[75px] right-0 bg-white shadow-md rounded-lg p-4 notification--list">
          <ul>
            {Messages?.length > 0 ? (
              Messages?.slice(0, 5).map((item, index) => (
                <li
                  key={item?.id || index}
                  onClick={() => handleNotificationClick(item._id)}
                  className="cursor-pointer"
                >
                  <span className="dropdown-item">{item?.message}</span>
                </li>
              ))
            ) : (
              <p>No New Notification</p>
            )}
          </ul>
          {Messages?.length > 0 && (
            <div className="notification--list !p-0 !pb-[15px]">
              <ul className="!p-0 ">
                <Link
                  to="/all-notification"
                  className="view--all--notifications"
                >
                  View All
                </Link>
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TopBar;
